import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import TableComp from '../../container/nestedTable/nestedTable'
import './finBalanceTab.css';



const BalanceSheet = (props) => {
    const [fiscal, setFiscal] = useState('Annual')
    return (
        <div>
            <div className='incomeTab'>
                <div className='parentInc'>
                    <div className='childInc'>
                        <h4 style={{ fontFamily: 'OpenSans', color: '#2924A6' }}>Balance Sheet</h4>
                        <Typography sx={{ fontFamily: 'OpenSans', fontSize: '12px' }}>All values in Millions, {props?.currency?.[0]?.value}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <span onClick={() => { setFiscal('Quarterly') }}
                                style={{
                                    fontWeight: fiscal === 'Quarterly' ? 900 : 'normal',
                                    color: fiscal === 'Quarterly' ? '#2924A6' : 'black',
                                    cursor: 'pointer',
                                    paddingRight: '10px',
                                    borderRight: '1px solid black',
                                    marginRight: '-1px'
                                }}>
                                Quarterly
                            </span>
                        </div>
                        <div style={{ flex: 1 }}>
                            <span onClick={() => { setFiscal('Annual') }}
                                style={{
                                    fontWeight: fiscal === 'Annual' ? 900 : 'normal',
                                    color: fiscal === 'Annual' ? '#2924A6' : 'black',
                                    cursor: 'pointer',
                                    paddingLeft: '10px'
                                }}>
                                Annual
                            </span>
                        </div>
                    </div>
                </div>
                <div >
                    <TableComp data={fiscal === "Annual" ? props.data : props.qtrlyData} headerVal="Fiscal Year" />
                </div>
            </div>
        </div >
    )
}

export default BalanceSheet;