export const DEF_FIN=`DEF_FIN`;

export const GET_SEARCH ='GET_SEARCH';
export const SET_SEARCH ='SET_SEARCH';

export const GET_INCOME_STATEMENT_YEARLY ='GET_INCOME_STATEMENT_YEARLY';
export const SET_INCOME_STATEMENT_YEARLY ='SET_INCOME_STATEMENT_YEARLY';
export const GET_BAL_SHEET_QTRLY='GET_BAL_SHEET_QTRLY';
export const SET_BAL_SHEET_QTRLY ='SET_BAL_SHEET_QTRLY';
export const GET_CASH_FLOW_QTRLY ='GET_CASH_FLOW_QTRLY';
export const SET_CASH_FLOW_QTRLY ='SET_CASH_FLOW_QTRLY';
export const GET_KEY_RATIO_YEARLY ='GET_KEY_RATIO_YEARLY';
export const SET_KEY_RATIO_YEARLY ='SET_KEY_RATIO_YEARLY';

export const GET_BALANCE_SHEET_YEARLY ='GET_BALANCE_SHEET_YEARLY';
export const SET_BALANCE_SHEET_YEARLY ='SET_BALANCE_SHEET_YEARLY';

export const GET_CASH_FLOW_YEARLY ='GET_CASH_FLOW_YEARLY';
export const SET_CASH_FLOW_YEARLY ='SET_CASH_FLOW_YEARLY';

export const GET_INCOME_STATEMENT_QUARTERLY ='GET_INCOME_STATEMENT_QUARTERLY';
export const SET_INCOME_STATEMENT_QUARTERLY ='SET_INCOME_STATEMENT_QUARTERLY';

export const GET_AVERAGE_GROWTH ='GET_AVERAGE_GROWTH';
export const SET_AVERAGE_GROWTH ='SET_AVERAGE_GROWTH';

export const GET_REVENUE_NETPROFIT ='GET_REVENUE_NETPROFIT';
export const SET_REVENUE_NETPROFIT ='SET_REVENUE_NETPROFIT';

export const GET_PEER_TABLE ='GET_PEER_TABLE';
export const SET_PEER_TABLE ='SET_PEER_TABLE';

export const setDefFin=(data)=>({
    type:DEF_FIN,
    data
})


export const getFinSearchInfo =()=>({type:GET_SEARCH});

export const setFinSearchInfo=(data)=>({
    type:SET_SEARCH,
    data
})

export const getIncomeStatementYearlyInfo =(params)=>({type:GET_INCOME_STATEMENT_YEARLY,companyCode:params});

export const setIncomeStatementYearlyInfo=(data)=>({
    type:SET_INCOME_STATEMENT_YEARLY,
    data
})
export const getBalSheetQrtlyInfo =(params)=>({type:GET_BAL_SHEET_QTRLY,companyCode:params});

export const setBalSheetQrtlyInfo=(data)=>({
    type:SET_BAL_SHEET_QTRLY,
    data
})
export const getCashFlowQrtlyInfo =(params)=>({type:GET_CASH_FLOW_QTRLY,companyCode:params});

export const setCashFlowQrtlyInfo=(data)=>({
    type:SET_CASH_FLOW_QTRLY,
    data
})
export const getKeyRatioYearlyInfo =(params)=>({type:GET_KEY_RATIO_YEARLY,companyCode:params});

export const setKeyRatioYearlyInfo=(data)=>({
    type:SET_KEY_RATIO_YEARLY,
    data
})
export const getBalanceSheetYearlyInfo =(params)=>({type:GET_BALANCE_SHEET_YEARLY,companyCode:params});

export const setBalanceSheetYearlyInfo=(data)=>({
    type:SET_BALANCE_SHEET_YEARLY,
    data
})

export const getCashFlowYearlyInfo =(params)=>({type:GET_CASH_FLOW_YEARLY,companyCode:params});

export const setCashFlowYearlyInfo=(data)=>({
    type:SET_CASH_FLOW_YEARLY,
    data
})


export const getIncomeStatementQuarterlyInfo =(params)=>({type:GET_INCOME_STATEMENT_QUARTERLY,companyCode:params});

export const setIncomeStatementQuarterlyInfo=(data)=>({
    type:SET_INCOME_STATEMENT_QUARTERLY,
    data
})

export const getAverageGrowthInfo =(params)=>({type:GET_AVERAGE_GROWTH,companyCode:params});

export const setAverageGrowthInfo=(data)=>({
    type:SET_AVERAGE_GROWTH,
    data
})

export const getRevenueNetprofitInfo =(params)=>({type:GET_REVENUE_NETPROFIT,companyCode:params});

export const setRevenueNetprofitInfo=(data)=>({
    type:SET_REVENUE_NETPROFIT,
    data
})

export const getPeerTableInfo =(params)=>({type:GET_PEER_TABLE,companyCode:params});

export const setPeerTableInfo=(data)=>({
    type:SET_PEER_TABLE
    ,
    data
})