import { takeLatest, put, all, call } from 'redux-saga/effects';
import api, { apiImage } from '../../service/apiService';
import {
    GET_ORG, GET_SEARCH, setOrgSearchInfo, setOrgInfo, GET_ORG_FLEX_INFO, setOrgFlexInfo, GET_ORG_MARGIN_ANALYSIS, setOrgMarginInfo,
    GET_ORG_KEY_RATIOS_DATA, setOrgKeyRatiosDataInfo, GET_ORG_BULLETIN_WINDOW, setOrgBulletinWindowInfo, setOrgsKeyStrategyInfo, GET_ORG_KEY_STRATEGY,
    GET_ORG_KEY_EXECUTIVES, setKeyExecutivesInfo, GET_ORG_KEY_COMPETITORS, setOrgKeyCompetitorsaInfo, GET_LATEST_NEWS, setLatestNewsInfo
} from '../../components/screens/Oranizational_Overview/orgAction';
import {
    GET_INCOME_STATEMENT_YEARLY, setIncomeStatementYearlyInfo, GET_BALANCE_SHEET_YEARLY, setBalanceSheetYearlyInfo,
    GET_CASH_FLOW_YEARLY, setCashFlowYearlyInfo,
    GET_INCOME_STATEMENT_QUARTERLY, setIncomeStatementQuarterlyInfo,
    GET_BAL_SHEET_QTRLY, setBalSheetQrtlyInfo,
    GET_CASH_FLOW_QTRLY, setCashFlowQrtlyInfo,
    GET_KEY_RATIO_YEARLY, setKeyRatioYearlyInfo,
    GET_AVERAGE_GROWTH, setAverageGrowthInfo,
    GET_REVENUE_NETPROFIT, setRevenueNetprofitInfo,
    GET_PEER_TABLE, setPeerTableInfo
} from '../../components/screens/Financial/finAction';
import {
    GET_CUST_SAT_PARAMS, setCustSatParams, GET_GENDER_PARAMS, setGenderParams,
    GET_AGE_PARAMS, setAgeParams, GET_POS_PARAMS, setPosParams, GET_CRITICAL_PARAMS, setCriticalParams,
    GET_WEB_SCRAP_PARAMS, setWebScrapParams, GET_WORD_CLOUD_PARAMS, setWordCloudParams
} from '../../components/screens/VoiceofCustomer/voiceAction';
import { GET_OVERALL_TRAFFIC, setOverallTraficInfo, setGraphDataInfo, GET_GRAPH_DATA } from '../../components/screens/DigitalResearch/digitalAction';
import {
    GET_COMPETITORS, setbenchmarkCompetitorsInfo,
    GET_FINANCIAL_PERFOMANCE, setbenchmarkFinancialInfo,
    GET_CUSTOMER_SATISFACTION, setbenchmarkCustomerSatisfactionInfo,
    GET_DIGITAL_PERFORMANCE, setbenchmarkDigitalPerformanceInfo
} from '../../components/screens/benchmark/benchAction';
import { GET_ALL_RESOURCES, setallResourcesInfo } from '../../components/screens/Resources/resourceAction';
import { formatLineChartData, formatBulletinData, formatMixedChart, formatAgeChart, formatdigitalScreenChart } from '../../utils/utils'
import {
    orgs, orgsDescription, orgsFlexbox, orgsMarginAnalysis, orgsKeyRatiosData, orgsBulletinWindow,
    finIncomeStatementYearly, finbalanceSheetYearly, finCashFlowYearly, finIncomeStatementQuarterly,
    finBalanceSheetQuarterly, finCashFlowQuarterly, finKeyRatioYearly,
    finAveragegrowth, finRevenueNetprofit, voiceCustParams, voiceGenderParams,
    voiceAgeParams, voicePosParams, voiceCriticalParams, voiceWebParams,
    voiceWordCloudParams, digitalGraphDataParams, digitalOverallTrafficParams,
    orgsKeyExecutives, orgsKeyCompetitors, benchmarkingCompetitorParams, benchmarkFinancialParams,
    benchmarkCustomerSatisfactionParams, benchmarkDigitalPerformanceParams, finPeerTable, orgsKeyStrategy,
    latestNews, getAllResources
} from '../../service/apiUrls';
import { serviceInProgress } from '../../components/container/loader/loaderAction';
import { CandlestickChart } from '@mui/icons-material';


function* getAllResourcesInfo(params) {
    yield put(serviceInProgress(true));
    let orgList = yield call(() => { return api(`${getAllResources.url}/${params.companyCode}/getallresources`, getAllResources.method, ``) })
    // console.log(orgList?.data?.resources, `all resources data`);
    yield put(setallResourcesInfo(orgList?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getSearchInfo() {
    yield put(serviceInProgress(true));
    let orgList = yield call(() => { return api(orgs.url, orgs.method, ``) })
    //    console.log(orgList?.data,`orgList`);
    yield put(setOrgSearchInfo(orgList?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getOrgInfo(params) {
    yield put(serviceInProgress(true));
    let orgList = yield call(() => { return api(`${orgsDescription.url}/${params.companyCode}`, orgsDescription.method, ``) })
    //    console.log(orgList?.data,`orgList`);
    //    console.log("check val", orgList.data.data[0].description)
    yield put(setOrgInfo(orgList?.data?.data[0]?.description));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getOrgFlexInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let orgList = yield call(() => { return api(`${orgsFlexbox.url}/${params.companyCode}`, orgsFlexbox.method, ``) })
        //    console.log(orgList?.data,`orgList`);
        //    console.log("check val", orgList.data.data[0])
        yield put(setOrgFlexInfo(orgList?.data?.data[0]));
        yield put(serviceInProgress(false));
        //yield put(setOrgInfo(orgList));
    }
    catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getOrgMarginAnalysisInfo(params) {
    yield put(serviceInProgress(true));
    try {
        let orgList = yield call(() => { return api(`${orgsMarginAnalysis.url}/${params.companyCode}`, orgsMarginAnalysis.method, ``) })
        //    console.log(orgList?.data,`orgList chart`);
        //    console.log("check val", orgList.data.data)
        let formattedResponse = formatLineChartData(orgList?.data)
        yield put(setOrgMarginInfo(formattedResponse));
        yield put(serviceInProgress(false));
        //yield put(setOrgInfo(orgList));
    }
    catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getOrgKeyExecutivesInfo(params) {
    yield put(serviceInProgress(true));
    let orgList = yield call(() => { return api(`${orgsKeyExecutives.url}/${params.companyCode}`, orgsKeyExecutives.method, ``) })
    //    console.log(orgList?.data,`orgList key exe`);
    //    console.log("check key exe", orgList.data)
    yield put(setKeyExecutivesInfo(orgList?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getOrgKeyRatiosdataInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let orgList = yield call(() => { return api(`${orgsKeyRatiosData.url}/${params.companyCode}`, orgsKeyRatiosData.method, ``) })
        //    console.log(orgList?.data,`orgList`);
        //    console.log("check val", orgList.data.headers)
        // let formattedResponse = modifyDataObjects(orgList?.data)
        yield put(setOrgKeyRatiosDataInfo(orgList?.data));
        yield put(serviceInProgress(false));
        //yield put(setOrgInfo(orgList));
    }
    catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getOrgBulletinWindowInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let orgList = yield call(() => { return api(`${orgsBulletinWindow.url}/${params.companyCode}`, orgsBulletinWindow.method, ``) })
        //    console.log(orgList?.data,`orgList`);
        //    console.log("check val bullet", orgList.data.data)
        let formattedResponse = formatBulletinData(orgList?.data?.data)
        yield put(setOrgBulletinWindowInfo(formattedResponse));
        yield put(serviceInProgress(false));
        //yield put(setOrgInfo(orgList));
    }
    catch (error) {
        yield put(serviceInProgress(false));
    }
}


function* getOrgKeyCompetitorsInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let orgList = yield call(() => { return api(`${orgsKeyCompetitors.url}/${params.companyCode}`, orgsKeyCompetitors.method, ``) })
        // console.log(orgList?.data,`org key comp`);
        // console.log('org key comp+',orgList?.data?.data);
        // let formattedResponse = formatorgKeyCompetitors(orgList?.data)
        yield put(setOrgKeyCompetitorsaInfo(orgList?.data));
        yield put(serviceInProgress(false));
    }
    catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getOrgKeyStrategyInfo(params) {
    yield put(serviceInProgress(true));
    let orgList = yield call(() => { return api(`${orgsKeyStrategy.url}/${params.companyCode}`, orgsKeyStrategy.method, ``) })
    yield put(setOrgsKeyStrategyInfo(orgList?.data?.data));
    yield put(serviceInProgress(false));
}

function* getLatestNewsInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let orgList = yield call(() => { return api(`${latestNews.url}/${params.companyCode}`, latestNews.method, ``) })
        yield put(setLatestNewsInfo(orgList?.data));
        yield put(serviceInProgress(false));
    }
    catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getFinIncomeYearlyInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let FinList = yield call(() => { return api(`${finIncomeStatementYearly.url}/${params.companyCode}`, finIncomeStatementYearly.method, ``) })
        yield put(setIncomeStatementYearlyInfo(FinList?.data));
        yield put(serviceInProgress(false));
    }
    catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getFinBalSheetQlyInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let FinList = yield call(() => { return api(`${finBalanceSheetQuarterly.url}/${params.companyCode}`, finBalanceSheetQuarterly.method, ``) })
        yield put(setBalSheetQrtlyInfo(FinList?.data));
        yield put(serviceInProgress(false));
    } catch (error) {
        yield put(serviceInProgress(false));
    }
}
function* getFinBalancesheetYearlyInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let FinList = yield call(() => { return api(`${finbalanceSheetYearly.url}/${params.companyCode}`, finbalanceSheetYearly.method, ``) })
        yield put(setBalanceSheetYearlyInfo(FinList?.data));
        yield put(serviceInProgress(false));
    } catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getFinCashFlowQlyInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let FinList = yield call(() => { return api(`${finCashFlowQuarterly.url}/${params.companyCode}`, finCashFlowQuarterly.method, ``) })
        yield put(setCashFlowQrtlyInfo(FinList?.data));
        yield put(serviceInProgress(false));
    } catch (error) {
        yield put(serviceInProgress(false));
    }
}
function* getFinCashflowYearlyInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let FinList = yield call(() => { return api(`${finCashFlowYearly.url}/${params.companyCode}`, finCashFlowYearly.method, ``) })
        yield put(setCashFlowYearlyInfo(FinList?.data));
        yield put(serviceInProgress(false));
        //yield put(setOrgInfo(orgList));
    } catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getFinKeyRatioYlyInfo(params) {
    yield put(serviceInProgress(true));
    let FinList = yield call(() => { return api(`${finKeyRatioYearly.url}/${params.companyCode}`, finKeyRatioYearly.method, ``) })
    //    console.log(FinList?.data,`FinList`);
    //    console.log("check key yearly", FinList.data)
    //    let formattedResponse = formatBulletinData(FinList?.data?.data)
    yield put(setKeyRatioYearlyInfo(FinList?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}


function* getFinIncomestatementYearlyInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let FinList = yield call(() => { return api(`${finIncomeStatementQuarterly.url}/${params.companyCode}`, finIncomeStatementQuarterly.method, ``) })
        yield put(setIncomeStatementQuarterlyInfo(FinList?.data));
        yield put(serviceInProgress(false));
    } catch (error) {
        yield put(serviceInProgress(false));
    }
}


function* getFinAverageGrowthInfo(params) {
    try {
        yield put(serviceInProgress(true));
        let FinList = yield call(() => { return api(`${finAveragegrowth.url}/${params.companyCode}`, finAveragegrowth.method, ``) })
        yield put(setAverageGrowthInfo(FinList?.data));
        yield put(serviceInProgress(false));
    } catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getPeerTableInfo(params) {
    yield put(serviceInProgress(true));
    let FinList = yield call(() => { return api(`${finPeerTable.url}/${params.companyCode}`, finPeerTable.method, ``) })
    //    console.log(FinList?.data,`FinList`);
    yield put(setPeerTableInfo(FinList?.data));
    yield put(serviceInProgress(false));
}

function* getFinRevenueNetprofitInfo(params) {
    yield put(serviceInProgress(true));
    let FinList = yield call(() => { return api(`${finRevenueNetprofit.url}/${params.companyCode}`, finRevenueNetprofit.method, ``) })
    //    console.log(FinList?.data,`FinList`);
    //    console.log("check revenue netprofit", FinList.data)
    let formattedResponse = formatMixedChart(FinList?.data)
    //    console.log("checkkk reven-----------------", formattedResponse)
    yield put(setRevenueNetprofitInfo(formattedResponse));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getVoiceCustParams(params) {
    yield put(serviceInProgress(true));
    let custData = yield call(() => { return api(`${voiceCustParams.url}/${params.companyCode}`, voiceCustParams.method, ``) })
    //    console.log(custData?.data,`FinList`);
    //    console.log("check cust pramas ", custData.data)
    yield put(setCustSatParams(custData?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getVoiceGenderParams(params) {
    yield put(serviceInProgress(true));
    let voiceData = yield call(() => { return api(`${voiceGenderParams.url}/${params.companyCode}`, voiceGenderParams.method, ``) })
    //    console.log(voiceData?.data,`FinList`);
    //    console.log("check cust pramas ", voiceData.data)
    yield put(setGenderParams(voiceData?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}
function* getVoiceAgeParams(params) {
    yield put(serviceInProgress(true));
    let voiceData = yield call(() => { return api(`${voiceAgeParams.url}/${params.companyCode}`, voiceAgeParams.method, ``) })
    //    console.log(voiceData?.data,`FinList`);
    //    console.log("check cust pramas ageeee", voiceData.data)
    let formattedResponse = formatAgeChart(voiceData?.data?.data[0])
    yield put(setAgeParams(formattedResponse));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}
function* getVoicePosParams(params) {
    yield put(serviceInProgress(true));
    let voiceData = yield call(() => { return api(`${voicePosParams.url}/${params.companyCode}`, voicePosParams.method, ``) })
    //    console.log(voiceData?.data,`FinList`);
    // console.log("check cust pramas ", voiceData?.data)
    //    let formattedResponse = formatMixedChart(custData?.data)
    yield put(setPosParams(voiceData?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}
function* getVoiceCriticalParams(params) {
    yield put(serviceInProgress(true));
    let voiceData = yield call(() => { return api(`${voiceCriticalParams.url}/${params.companyCode}`, voiceCriticalParams.method, ``) })
    // console.log("check cust pramas ", voiceData?.data)
    yield put(setCriticalParams(voiceData?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}
function* getVoiceWebParams(params) {
    yield put(serviceInProgress(true));
    let voiceData = yield call(() => { return api(`${voiceWebParams.url}/${params.companyCode}`, voiceWebParams.method, ``) })
    // console.log(voiceData?.data, `webscrapiing---List`);
    yield put(setWebScrapParams(voiceData?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}
function* getWordCloudParams(params) {
    try {
        yield put(serviceInProgress(true));
        let voiceData = yield call(() => { return apiImage(`${voiceWordCloudParams.url}/${params.companyCode}`, voiceWordCloudParams.method, ``) })
        //    console.log(voiceData?.data,`voice_wordcloud`);
        //    console.log("check word cloud pramas ", voiceData.data)
        if (voiceData.data) {
            var fileURL = window.URL.createObjectURL(voiceData.data);
            yield put(setWordCloudParams(fileURL));
        }
        //    let formattedResponse = formatMixedChart(custData?.data)
        yield put(serviceInProgress(false));
        //yield put(setOrgInfo(orgList));
    }
    catch (error) {
        yield put(serviceInProgress(false));
    }
}

function* getDigitalOverallTrafficInfo(params) {
    yield put(serviceInProgress(true));
    let DigitalList = yield call(() => { return api(`${digitalOverallTrafficParams.url}/${params.companyCode}`, digitalOverallTrafficParams.method, ``) })
    yield put(setOverallTraficInfo(DigitalList?.data?.data[0]));
    yield put(serviceInProgress(false));
}

function* getDigitalGraphDataInfo(params) {
    yield put(serviceInProgress(true));
    let DigitalList = yield call(() => { return api(`${digitalGraphDataParams.url}/${params.companyCode}`, digitalGraphDataParams.method, ``) })
    //    console.log(DigitalList?.data,`DigList`);
    //    console.log("check graph data", DigitalList.data)
    let formattedResponse = formatdigitalScreenChart(DigitalList?.data)
    //    console.log('digital chart',formattedResponse)
    yield put(setGraphDataInfo(formattedResponse));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getBenchMarkingKeyCompetitorsInfo(params) {
    yield put(serviceInProgress(true));
    let CompetitorList = yield call(() => { return api(`${benchmarkingCompetitorParams.url}/${params.companyCode}`, benchmarkingCompetitorParams.method, ``) })
    //  console.log(CompetitorList?.data,"Benchmark competitors")
    yield put(setbenchmarkCompetitorsInfo(CompetitorList.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getbenchmarkFinancialInfo(params) {
    yield put(serviceInProgress(true));
    let BenchmarkData = yield call(() => { return api(`${benchmarkFinancialParams.url}/${params.companyCode}`, benchmarkFinancialParams.method, ``) })
    //    console.log(BenchmarkData?.data,`benchList`);
    yield put(setbenchmarkFinancialInfo(BenchmarkData?.data));
    yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getbenchmarkCustomerSatisfactionlInfo(params) {
    //   yield put(serviceInProgress(true));
    let CustomerSatisfactionData = yield call(() => { return api(`${benchmarkCustomerSatisfactionParams.url}/${params.companyCode}`, benchmarkCustomerSatisfactionParams.method, ``) })
    //    console.log(CustomerSatisfactionData?.data,`CustomerSatisfactionList`);
    yield put(setbenchmarkCustomerSatisfactionInfo(CustomerSatisfactionData?.data));
    // yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}

function* getbenchmarkDigitalPerformanceInfo(params) {
    //  yield put(serviceInProgress(true));
    let DigitalPerformanceData = yield call(() => { return api(`${benchmarkDigitalPerformanceParams.url}/${params.companyCode}`, benchmarkDigitalPerformanceParams.method, ``) })
    //    console.log(DigitalPerformanceData?.data,`DigitalPerformanceList`);
    yield put(setbenchmarkDigitalPerformanceInfo(DigitalPerformanceData?.data));
    //  yield put(serviceInProgress(false));
    //yield put(setOrgInfo(orgList));
}
function* watcherSaga() {
    yield takeLatest(GET_SEARCH, getSearchInfo)
    yield takeLatest(GET_ORG, getOrgInfo)
    yield takeLatest(GET_ORG_FLEX_INFO, getOrgFlexInfo)
    yield takeLatest(GET_ORG_MARGIN_ANALYSIS, getOrgMarginAnalysisInfo)
    yield takeLatest(GET_ORG_KEY_RATIOS_DATA, getOrgKeyRatiosdataInfo)
    yield takeLatest(GET_ORG_BULLETIN_WINDOW, getOrgBulletinWindowInfo)
    yield takeLatest(GET_ORG_KEY_EXECUTIVES, getOrgKeyExecutivesInfo)
    yield takeLatest(GET_ORG_KEY_COMPETITORS, getOrgKeyCompetitorsInfo)
    yield takeLatest(GET_ORG_KEY_STRATEGY, getOrgKeyStrategyInfo)
    yield takeLatest(GET_LATEST_NEWS, getLatestNewsInfo)



    yield takeLatest(GET_INCOME_STATEMENT_YEARLY, getFinIncomeYearlyInfo)
    yield takeLatest(GET_BAL_SHEET_QTRLY, getFinBalSheetQlyInfo)
    yield takeLatest(GET_CASH_FLOW_QTRLY, getFinCashFlowQlyInfo)
    yield takeLatest(GET_KEY_RATIO_YEARLY, getFinKeyRatioYlyInfo)
    yield takeLatest(GET_BALANCE_SHEET_YEARLY, getFinBalancesheetYearlyInfo)
    yield takeLatest(GET_CASH_FLOW_YEARLY, getFinCashflowYearlyInfo)
    yield takeLatest(GET_INCOME_STATEMENT_QUARTERLY, getFinIncomestatementYearlyInfo)
    yield takeLatest(GET_AVERAGE_GROWTH, getFinAverageGrowthInfo)
    yield takeLatest(GET_REVENUE_NETPROFIT, getFinRevenueNetprofitInfo)
    yield takeLatest(GET_PEER_TABLE, getPeerTableInfo)


    yield takeLatest(GET_CUST_SAT_PARAMS, getVoiceCustParams)
    yield takeLatest(GET_GENDER_PARAMS, getVoiceGenderParams)
    yield takeLatest(GET_AGE_PARAMS, getVoiceAgeParams)
    yield takeLatest(GET_POS_PARAMS, getVoicePosParams)
    yield takeLatest(GET_CRITICAL_PARAMS, getVoiceCriticalParams)
    yield takeLatest(GET_WEB_SCRAP_PARAMS, getVoiceWebParams)
    yield takeLatest(GET_WORD_CLOUD_PARAMS, getWordCloudParams)

    yield takeLatest(GET_OVERALL_TRAFFIC, getDigitalOverallTrafficInfo)
    yield takeLatest(GET_GRAPH_DATA, getDigitalGraphDataInfo)

    yield takeLatest(GET_FINANCIAL_PERFOMANCE, getbenchmarkFinancialInfo)
    yield takeLatest(GET_COMPETITORS, getBenchMarkingKeyCompetitorsInfo)
    yield takeLatest(GET_CUSTOMER_SATISFACTION, getbenchmarkCustomerSatisfactionlInfo)
    yield takeLatest(GET_DIGITAL_PERFORMANCE, getbenchmarkDigitalPerformanceInfo)


    yield takeLatest(GET_ALL_RESOURCES, getAllResourcesInfo)
}

function* rootSaga() {
    yield all([watcherSaga()])
}
export default rootSaga;