import { GET_ORG, SET_ORG } from './orgAction';

const initialState = {
    OrgName: ``
}
const OrgDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SEARCH': {
            return Object.assign({}, state, {
                orgsSearch: action.value
            });
        }
        case 'SET_SEARCH': {
            return Object.assign({}, state, {
                orgsSearch: action.data
            });
        }
        case 'DEF_ORG': {
            return Object.assign({}, state, {
                selOrg: action.data
            });
        }

        case 'GET_ORG': {
            return Object.assign({}, state, {
                orgs: action.value
            });
        }
        case 'SET_ORG': {
            return Object.assign({}, state, {
                orgs: action.data
            });
        }


        case 'GET_ORG_FLEX_INFO': {
            return Object.assign({}, state, {
                orgsFlex: action.value
            });
        }
        case 'SET_ORG_FLEX_INFO': {
            return Object.assign({}, state, {
                orgsFlex: action.data
            });
        }


        case 'GET_ORG_MARGIN_ANALYSIS': {
            return Object.assign({}, state, {
                orgsMargin: action.value
            });
        }
        case 'SET_ORG_MARGIN_ANALYSIS': {
            return Object.assign({}, state, {
                orgsMargin: action.data
            });
        }

        case 'GET_ORG_KEY_EXECUTIVES': {
            return Object.assign({}, state, {
                orgsKeyExecutives: action.value
            });
        }
        case 'SET_ORG_KEY_EXECUTIVES': {
            return Object.assign({}, state, {
                orgsKeyExecutives: action.data
            });
        }

        case 'GET_ORG_KEY_RATIOS_DATA': {
            return Object.assign({}, state, {
                orgsKeyratiosdata: action.value
            });
        }
        case 'SET_ORG_KEY_RATIOS_DATA': {
            return Object.assign({}, state, {
                orgsMarginKeyratiosdata: action.data
            });
        }

        case 'GET_ORG_BULLETIN_WINDOW': {
            return Object.assign({}, state, {
                orgsBulletinWindow: action.value
            });
        }
        case 'SET_ORG_BULLETIN_WINDOW': {
            return Object.assign({}, state, {
                orgsBulletinWindow: action.data
            });
        }

        case 'GET_ORG_KEY_COMPETITORS': {
            return Object.assign({}, state, {
                orgsKeyCompetitors: action.value
            });
        }
        case 'SET_ORG_KEY_COMPETITORS': {
            return Object.assign({}, state, {
                orgsKeyCompetitors: action.data
            });
        }

        case 'GET_ORG_KEY_STRATEGY': {
            return Object.assign({}, state, {
                orgsKeyStrategy: action.value
            });
        }
        case 'SET_ORG_KEY_STRATEGY': {
            return Object.assign({}, state, {
                orgsKeyStrategy: action.data
            });
        }
        case 'GET_LATEST_NEWS': {
            return Object.assign({}, state, {
                latestNews: action.value
            });
        }
        case 'SET_LATEST_NEWS': {
            return Object.assign({}, state, {
                latestNews: action.data
            });
        }

        default: {
            return Object.assign({}, state);
        }
    }
}
export default OrgDetails;