const Digitalbox = (params) => {

  return (
    <div className='flex-main-container'>
      <div className='flex-title'>
        <h3>Overall Traffic</h3>
        <h7 style={{ color: 'black' }}>Last month</h7>
      </div>
      <div class="flex-container">
        <div class="card"><p>Total Visits</p><h3>{params?.data?.total_visits}</h3></div>
        <div class="card"><p>Bounce Rate</p><h3>{params?.data?.bounce_rate}</h3></div>
        <div class="card"><p>Page Per Visit</p><h3>{params?.data?.pages_per_visit}</h3></div>
        <div class="card"><p>Average Visit Duration</p><h3> {params?.data?.avg_visit_duration}</h3></div>
        <div class="card"><p>Conversation Rate</p><h3>{params?.data?.conversion_rate}</h3></div>
        <div class="card"><p>Average Order Value</p><h3>{params?.data?.conversion_rate}</h3></div>
      </div>
    </div>

  );
};

export default Digitalbox;