import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DynamicTable from '../dynamicTable/dynamicTable';
import './accordion.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ }) => ({
    backgroundColor: '#F5F6FB',
    borderRadius: '10px',
    color: 'black',
    fontSize: '14px',
    fontFamily: 'OpenSans',
    '& .MuiSvgIcon-root': {
        color: 'black'
    }
}));

const AccordionComponent = (props) => {
    const [expanded, setExpanded] = useState(false)
    const onClick = () => {
        setExpanded(!expanded)
    }

    return (
        <div className='accordion'>
            <Accordion defaultExpanded={props.expanded}>
                <AccordionSummary
                    // expandIcon={props.expanded ? <KeyboardArrowDownIcon color="white" />
                    //     : <KeyboardArrowRightIcon color="secondary" />}
                    expanded={props.expanded}
                    onClick={onClick}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                >
                    <h3>{props.heading}</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <DynamicTable data={props?.content} />
                </AccordionDetails>
            </Accordion>
        </div>
    )

}

export default AccordionComponent