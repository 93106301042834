
import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button, Grid } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { PublicClientApplication } from '@azure/msal-browser';
import msalConfig from "../Msal/authConfig";

const msalClient = new PublicClientApplication(msalConfig);
/**
 * Renders a sign-out button
 */
const LogoutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        sessionStorage.clear();
        // Send a logout signal through the broadcast channel
        const broadcastChannel = new BroadcastChannel('logoutChannel');
        broadcastChannel.postMessage('logout');
        if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
        }
    }
    return (
        <Grid item sx={{ position: 'absolute', bottom: 0 }}>
            <Button variant="contained" style={{ backgroundColor: '#F5F6FB', color: 'black', fontFamily: 'openSans', width: '200px', borderRadius: '10px', }}
                onClick={() => handleLogout("redirect")}><ExitToAppIcon />Sign out</Button>
        </Grid>
    )
}
export default LogoutButton;