export const GET_SEARCH = 'GET_SEARCH';
export const SET_SEARCH = 'SET_SEARCH';

export const GET_ORG = 'GET_ORG';
export const SET_ORG = 'SET_ORG';
export const SET_ORG_FLEX_INFO = 'SET_ORG_FLEX_INFO';
export const GET_ORG_FLEX_INFO = 'GET_ORG_FLEX_INFO';
export const SET_ORG_MARGIN_ANALYSIS = 'SET_ORG_MARGIN_ANALYSIS';
export const GET_ORG_MARGIN_ANALYSIS = 'GET_ORG_MARGIN_ANALYSIS';
export const SET_ORG_KEY_EXECUTIVES = 'SET_ORG_KEY_EXECUTIVES';
export const GET_ORG_KEY_EXECUTIVES = 'GET_ORG_KEY_EXECUTIVES';
export const SET_ORG_KEY_RATIOS_DATA = 'SET_ORG_KEY_RATIOS_DATA';
export const GET_ORG_KEY_RATIOS_DATA = 'GET_ORG_KEY_RATIOS_DATA';
export const SET_ORG_BULLETIN_WINDOW = 'SET_ORG_BULLETIN_WINDOW';
export const GET_ORG_BULLETIN_WINDOW = 'GET_ORG_BULLETIN_WINDOW';
export const SET_ORG_KEY_COMPETITORS = 'SET_ORG_KEY_COMPETITORS';
export const GET_ORG_KEY_COMPETITORS = 'GET_ORG_KEY_COMPETITORS';
export const SET_ORG_KEY_STRATEGY = 'SET_ORG_KEY_STRATEGY';
export const GET_ORG_KEY_STRATEGY = 'GET_ORG_KEY_STRATEGY';
export const DEF_ORG = `DEF_ORG`;
export const GET_LATEST_NEWS = 'GET_LATEST_NEWS';
export const SET_LATEST_NEWS = 'SET_LATEST_NEWS';


export const getLatestNewsInfo = (params) => ({ type: GET_LATEST_NEWS, companyCode: params });

export const setLatestNewsInfo = (data) => ({
    type: SET_LATEST_NEWS,
    data
})


export const setDefOrg = (data) => ({
    type: DEF_ORG,
    data
})


export const getOrgSearchInfo = () => ({ type: GET_SEARCH });

export const setOrgSearchInfo = (data) => ({
    type: SET_SEARCH,
    data
})

export const getOrgInfo = (params) => ({ type: GET_ORG, companyCode: params });

export const setOrgInfo = (data) => ({
    type: SET_ORG,
    data
})



export const getOrgFlexInfo = (params) => ({ type: GET_ORG_FLEX_INFO, companyCode: params });

export const setOrgFlexInfo = (data) => ({
    type: SET_ORG_FLEX_INFO,
    data
})



export const getOrgMarginInfo = (params) => ({ type: GET_ORG_MARGIN_ANALYSIS, companyCode: params });

export const setOrgMarginInfo = (data) => ({
    type: SET_ORG_MARGIN_ANALYSIS,
    data
})

export const getOrgKeyExecutivesInfo = (params) => ({ type: GET_ORG_KEY_EXECUTIVES, companyCode: params });

export const setKeyExecutivesInfo = (data) => ({
    type: SET_ORG_KEY_EXECUTIVES,
    data
})

export const getOrgKeyRatiosDataInfo = (params) => ({ type: GET_ORG_KEY_RATIOS_DATA, companyCode: params });

export const setOrgKeyRatiosDataInfo = (data) => ({
    type: SET_ORG_KEY_RATIOS_DATA,
    data
})

export const getOrgBulletinWindowInfo = (params) => ({ type: GET_ORG_BULLETIN_WINDOW, companyCode: params });

export const setOrgBulletinWindowInfo = (data) => ({
    type: SET_ORG_BULLETIN_WINDOW,
    data
})

export const getOrgKeyCompetitorsInfo = (params) => ({ type: GET_ORG_KEY_COMPETITORS, companyCode: params });

export const setOrgKeyCompetitorsaInfo = (data) => ({
    type: SET_ORG_KEY_COMPETITORS,
    data
})

export const getOrgsKeyStrategyInfo = (params) => ({ type: GET_ORG_KEY_STRATEGY, companyCode: params });

export const setOrgsKeyStrategyInfo = (data) => ({
    type: SET_ORG_KEY_STRATEGY,
    data
})