import { LocationDisabledSharp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import Plot from 'react-plotly.js';
import { FormatChartData } from '../../../../utils/utils';



const TotalVisitsBarChart = (props) => {
  if (props?.data?.data?.[0] === '-') {
    return (
      <Typography sx={{ fontFamily: 'OpenSans', marginBottom: '20px', textAlign: 'center' }}>No data available</Typography>
    )
  }

  let labels = []
  let values = []
  props?.data?.data?.map((item) => {
    labels.push(item?.month)
    values.push(item?.value)
  })

  const data = [
    {
      x: FormatChartData(labels),
      y: FormatChartData(values),
      type: 'bar',
      width: 0.4,
      text: values,
      textposition: 'top',
      marker: {
        color: ['#5C59BB', '#5C59BB', '#5C59BB'],
      },
      margin: {
        p: '20px'
      }

    }
  ];
  const layout = {
    yaxis: {
      tickvals: [],
      ticktext: []
    },
  }


  return (
    <>
      {/* <Typography style={Style.title}>Total Visits Last 3 Months (in M)</Typography> */}
      <Plot
        data={data}
        useResizeHandler={true}
        config={{ responsive: true }}
        style={{ width: '100%', height: '80%' }}
        layout={layout}
      />
    </>
  );
};
const Style = {
  container: {
    borderRadius: '10px',
    backgroundColor: 'white',
    marginTop: '25px',
    marginBottom: '20px'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    padding: '10px'
  },
}
export default TotalVisitsBarChart;