import React, { useState } from 'react';
import { Grid, Typography, Divider, List, ListItem, ListItemText, Link, Box } from '@mui/material';
import { Skeleton } from '@mui/material';
import { useEffect } from 'react';

function NewsContainer(props) {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [data, setData] = useState(true)
    useEffect(() => {
        if (props?.latestNews?.item === undefined) {
            setData(false)
        }
        else {
            setData(true)
        }
    }, [props?.latestNews?.item])
    // return (
    //     <div style={Styles.container}>
    //         <Typography style={Styles.title}>Latest News</Typography>
    //         <Typography sx={{ fontFamily: 'OpenSans', textAlign: 'center', marginTop: '15px' }}>No data Available</Typography>
    //     </div>
    // )
    const SkeletonTheme = () => {
        return (
            <Box sx={{ width: 240, alignItems: 'center' }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
            </Box>
        )
    }

    const filteredNews = (data) => {
        let latestnews = []
        const news = data
        for (let i = 0; i < 3; i++) {
            let newVal = news?.[i];
            latestnews?.push(newVal)
        }
        return latestnews;
    }

    const handleExpand = (index) => {
        setExpandedIndex(index === expandedIndex ? null : index);
    }

    const isDataAvaialable = props?.latestNews?.item
    return (
        <div>
            {isDataAvaialable &&
                <div style={Styles.container}>
                    <Typography style={Styles.title}>Latest News</Typography>
                    <List>
                        {filteredNews(props?.latestNews?.item)?.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={<Link href={item?.link} target="_blank" underline="hover" rel="noopener noreferrer" sx={{ color: '#2924A6' }}><Typography sx={{ color: '#2924A6', fontWeight: 700, fontSize: '14px', fontFamily: 'OpenSans' }}>{data ? item?.title : <SkeletonTheme />}</Typography></Link>}
                                        secondary={
                                            <>
                                                <Typography sx={{ color: 'black', fontSize: '12px', fontFamily: 'OpenSans' }}>
                                                    {expandedIndex === index ? item?.description : `${item?.description.slice(0, 90)}... `}
                                                    {item?.description?.length > 100 &&
                                                        <Typography
                                                            sx={{ color: 'black', cursor: 'pointer', fontWeight: 700, fontSize: '12px', fontFamily: 'OpenSans', display: 'inline' }}
                                                            onClick={() => handleExpand(index)}
                                                        >
                                                            {expandedIndex === index ? 'Read Less' : 'Read More'}
                                                        </Typography>
                                                    }
                                                </Typography>
                                                <Typography sx={{ color: 'black', fontWeight: 800, fontSize: '13px', fontFamily: 'OpenSans' }}>
                                                    {item?.pubDate}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                                {index < filteredNews(props?.latestNews?.item)?.length - 1 && <Divider sx={{ borderWidth: 1, color: 'white' }} />}
                            </React.Fragment>
                        ))}
                    </List>

                </div>

            }</div>)



}

const Styles = {
    container: {
        padding: '10px',
        backgroundColor: '#F5F6FB',
        marginTop: '10px',
        borderRadius: '10px',
        textAlign: 'justify',
        fontSize: '14px',
        fontFamily: 'OpenSans'
    },
    title: {
        fontWeight: 700,
        fontFamily: 'OpenSans'
    }
};

export default NewsContainer;
