const kycConstants = {
    searchOrgPlcHolder: `Enter the Organization Name`
}

export const menuItems = [
    { title: `Organizational Overview`, tag: `diagram`, url: `/org` },
    { title: `Financial Overview`, tag: `planning`, url: `/fin/summary` },
    { title: `Benchmarking`, tag: `compare`, url: `/benchmark` },
    { title: `Digital Research`, tag: `research`, url: `/digitalresearch` },
    { title: `Voice of Customer`, tag: `rating`, url: `/voiceofcustomer` },
    { title: `Industry Resources`, tag: `compare`, url: `/resources` },
]

export const leftTopPointer = [
    { title: `Headquaters`, value: `United States` },
    { title: `Sector`, value: `Speciality Retail` },
    { title: `Industry`, value: `Retail/ Wholesale` },
    { title: `Employees`, value: `68,000` },
    { title: `Sales or Revenue`, value: `11.00 B` },
    { title: `Website`, value: `https://www.autoparts.com` },
    { title: `Market Size`, value: `3%` },
    { title: `Revenue`, value: `+3.47%` },
    { title: `Category Ranking`, value: `#4` },
]

export const topOrgs = [
    { title: 'United Airlines', year: 1994 },
    { title: 'Air Asia', year: 1972 },
    { title: 'Albertsons', year: 1974 },
    { title: 'Kingfisher', year: 2008 },
    { title: 'M&M', year: 1957 },
    { title: "H&M", year: 1993 },
];



export default kycConstants;