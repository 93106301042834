import { Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import Plot from 'react-plotly.js';

const MixedChart = (props) => {
  // console.log('revdata', props?.data?.data?.datasets[0].data)
  // console.log('labels',props?.data?.data?.labels)
  const FormatChartData = (data) => {
    let newData = data
    let FormattedArray = []
    newData?.map(item => {
      let val = item.split(" ")
      FormattedArray.push(val[0])
    })
    return FormattedArray;
  }

  const data =
    [
      {
        x: props?.data?.data?.labels,
        y: FormatChartData(props?.data?.data?.datasets[0]?.data),
        name: 'Revenue',
        // type: 'line',
        text: props?.data?.data?.datasets[0].data,
        textposition: 'top center',
        type: 'scatter',
        mode: 'lines+markers',
        line: { color: '#5C59BB' },
      },
      {
        x: props?.data?.data?.labels,
        y: FormatChartData(props?.data?.data?.datasets[1]?.data),
        name: 'Net Profit',
        // type: 'line',
        text: props?.data?.data?.datasets[1]?.data,
        textposition: 'top center',
        type: 'scatter',
        mode: 'lines+markers',
        line: { color: '#F25260' },

      }
    ];
  const config = {
    showlegend: false
  };
  const layout = {
    // title: 'Revenue vs Net Profit',
    xaxis: { title: 'Year' },
    yaxis: { title: 'Amount' },
    barmode: 'group',
    yaxis: {
      tickvals: [],
      ticktext: []
    }
  };
  const Styles = {
    container: {
      borderRadius: '10px',
      backgroundColor: 'white',
      marginTop: '25px',
      marginBottom: '20px',
      fontFamily: 'OpenSans'
    },
    title: {
      fontWeight: 'bold',
      fontSize: '18px',
      color: '#2924A6',
      padding: '10px',
      fontFamily: 'OpenSans'
    },
  }
  return (
    <Container style={Styles.container}>
      <Typography style={Styles.title}>Revenue and Net Profit Trend</Typography>
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
        config={{ responsive: true }}
      />
    </Container>
  );
};


export default MixedChart;


// import { Typography } from '@mui/material';
// import React from 'react';
// import { Container } from 'react-bootstrap';
// import Plot from 'react-plotly.js';

// const MixedChart = (props) => {
//   const FormatChartData = (data) => {
//     let newData = data
//     let FormattedArray = []
//     newData?.map(item => {
//       let val = item.split(" ")
//       FormattedArray.push(val[0])
//     })
//     return FormattedArray;
//   }

//   const data = [
//     {
//       x: props?.data?.data?.labels,
//       y: FormatChartData(props?.data?.data?.datasets[0]?.data),
//       name: 'Revenue',
//       type: 'scatter',
//       text: props?.data?.data?.datasets[0].data,
//       textposition: 'top',
//       yaxis: 'y1',
//     },
//     {
//       x: props?.data?.data?.labels,
//       y: FormatChartData(props?.data?.data?.datasets[1]?.data),
//       name: 'Net Profit',
//       type: 'scatter',
//       text: props?.data?.data?.datasets[1]?.data,
//       textposition: 'top',
//       yaxis: 'y2',
//     }
//   ];

//   const layout = {
//     // title: 'Revenue vs Net Profit',
//     xaxis: { title: 'Year' },
//     yaxis: { side: 'left', domain: [0, 0.5], },
//     yaxis2: { side: 'right', domain: [0.5, 1], },
//     barmode: 'group',
//     yaxis: {
//       tickvals: [],
//       ticktext: []
//     }
//   };
//   const Styles = {
//     container: {
//       borderRadius: '10px',
//       backgroundColor: 'white',
//       marginTop: '25px',
//       marginBottom: '20px',
//       fontFamily: 'OpenSans'
//     },
//     title: {
//       fontWeight: 'bold',
//       fontSize: '20px',
//       color: '#2924A6',
//       padding: '10px',
//       fontFamily: 'OpenSans'
//     },
//   }
//   return (
//     <Container style={Styles.container}>
//       <Typography style={Styles.title}>Revenue and Net Profit Trend</Typography>
//       <Plot
//         data={data}
//         layout={layout}
//         style={{ width: '100%', height: '100%' }}
//         useResizeHandler={true}
//         config={{ responsive: true }}
//       />
//     </Container>
//   );
// };

// export default MixedChart;

