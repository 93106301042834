import React from "react";
import TableComp from '../../container/nestedTable/nestedTable'
import './finKeyTab.css'

function Keyrat(props) {
    return (
        <div>
            <div className='keyTab'>
                <h4 style={{ fontFamily: 'OpenSans', color: '#2924A6' }}>Key Ratios</h4>
                <div >
                    <TableComp data={props.data} headerVal="Annual" />
                </div>
            </div>
        </div >
    )
}
export default Keyrat;