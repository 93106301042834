import axios from 'axios';

export const api = (url, methodType, data) => {
    return axios({
        url: url,
        method: methodType,
        data: data,
        crossDomain: true,
        headers: {
            "Content-Type": " application/json",
        }
    })
}

export const apiImage = (url, methodType, data) => {
    try{
    return axios({
        url: url,
        method: methodType,
        data: data,
        crossDomain: true,        
    responseType:"blob",
        headers: {
            "Cache-control": "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
        }
    })
}
catch(err){
    return null
}
}

export default api;