
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import './digitalResearch.css'
import KYCMenu from "../../container/menu/Menu";
import SearchComplete from "../../container/search/Search";
import kycConstants, { topOrgs } from '../../../utils/constants';
import Digitalbox from "../../container/Flexboxes/OverallTraffic";
import Bulletin from "../../container/BulletinWindow/Bulletin";
import { getOverallTraficInfo, getGraphDataInfo } from "./digitalAction";
import { getOrgBulletinWindowInfo, getLatestNewsInfo } from "../Oranizational_Overview/orgAction";
import BarChart from "../../container/charts/barChart/BarChart";
import TotalVisitsBarChart from "../../container/charts/Total_Visits/TotalVisits";
import Loader from "../../container/loader/loader";
import { Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import ScrollButton from "../../container/ScrollToTop/ScrollUp";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";




function DigitalResearch() {
    const dispatch = useDispatch();
    const digital = useSelector((state) => state.AllDigitalData);
    const orgs = useSelector((state) => state.AllOrgs);
    const [companyCode, setCompanyCode] = useState('')
    let selOrg = sessionStorage.getItem(`org`)
    useEffect(() => {
        if (orgs.orgsSearch && (orgs.selOrg || selOrg)) {
            orgs.orgsSearch.forEach((item) => {
                if (item.company_name === (orgs.selOrg || selOrg))
                    setCompanyCode(item.company_code);
            })
        }
    }, [orgs.selOrg, orgs.orgsSearch])

    useEffect(() => {
        if (companyCode) {
            dispatch(getOverallTraficInfo(companyCode));
            dispatch(getGraphDataInfo(companyCode));
            dispatch(getOrgBulletinWindowInfo(companyCode));
            dispatch(getLatestNewsInfo(companyCode))

        }
    }, [companyCode, dispatch])

    const { instance } = useMsal();
    const location = useLocation();
    useEffect(() => {
        const msalkey = sessionStorage.getItem('msal.token.keys.9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d')
        if (!msalkey) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
        }
    }, [location, companyCode, dispatch])

    return (
        <div>

            <Loader />
            <div className="navbar">
                <nav>
                    <KYCMenu />
                </nav>
            </div>
            <div className="maincontent">

                <div className="subcontent">
                    <div className="searchbar">

                        <SearchComplete placeHolder={kycConstants.searchOrgPlcHolder}
                            topOrgs={topOrgs} />

                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <Typography style={Styles.title}>Digital Research</Typography>
                        {/* <Typography sx={{ fontSize: '12px', marginLeft: '10px', fontFamily: 'OpenSans', color: '#888888' }}>Data Sources: Similar Web</Typography> */}
                    </div>
                    <div className="digbox">
                        <Digitalbox data={digital?.digitalOverallTraffic} />
                    </div>
                    <div style={{ padding: '10px' }}>
                        <div className="market-channel-chart" style={{ height: '500px' }}>
                            <h4 className="h4">Total Visits Last 3 Months</h4>
                            <Typography sx={{ color: 'black', fontSize: '12px', fontFamily: 'OpenSans' }}>In Millions</Typography>
                            <TotalVisitsBarChart data={digital?.digitalGraphData?.last3months_visits} />
                        </div>
                    </div>
                    <div style={{ padding: '10px' }}>
                        <div className="market-channel-chart">
                            <h4 className="h4">Marketing Channels Distribution</h4>
                            <Typography sx={{ color: 'black', fontSize: '14px', fontFamily: 'OpenSans' }}>In %</Typography>

                            <div className="chart-container" style={{ height: '500px' }}>
                                <BarChart data={digital?.digitalGraphData?.marketing_channels_dist}></BarChart>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '10px' }}>
                        <div className="social-network-chart">
                            <h4 className="h4">Social Network Distribution</h4>
                            <Typography sx={{ color: 'black', fontSize: '14px', fontFamily: 'OpenSans' }}>In %</Typography>
                            <div className="chart-container" style={{ height: '500px' }}>
                                <BarChart data={digital?.digitalGraphData?.social_network_dist}></BarChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sidebar">

                <div className="digbull" >
                    <Bulletin data={orgs.orgsBulletinWindow} news={orgs.latestNews} />
                </div>

            </div>
            <ScrollButton />
        </div>

    )
}
const Styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '20px'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'Black',
        padding: '10px',
        paddingBottom: '0px'
    },
}
export default DigitalResearch;