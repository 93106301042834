
import React from 'react';
import Plot from 'react-plotly.js';
import { Typography, Box } from '@mui/material';
import './customerdemography.css'
import resultsNotFound from '../../../assets/imgs/errorHandlingPngs/resultsNotFound.png'
const MenWomenPieChart = (props) => {

  if (props?.menWomen?.data?.[0]?.Male === '-') {
    return (
      <Box maxWidth="md" style={Styles.container}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img className='logo' src={resultsNotFound} alt={`no tagLine`} class="center" style={{ height: '60%', width: '60%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
        </div>
      </Box>

    )
  }
  const genderData = [
    {
      values: [props?.menWomen?.data[0]?.Female, props?.menWomen?.data[0]?.Male],
      labels: ['Female', 'Male'],
      type: 'pie',
      marker: {
        colors: ['#F25260', '#5C59BB'],
      },
      // name: 'Gender'
    }
  ];
  return (
    <div>
      <Typography sx={{ fontFamily: 'OpenSans', color: '#888888', marginTop: '10px' }}>Gender Distribution</Typography>

      <Plot
        data={genderData}
        layout={{
          width: '350',
          height: '400',
          marginLeft: '40px',
          // title: 'Men vs Women',
          yaxis: {
            tickvals: [],
            ticktext: []
          }
        }}
        useResizeHandler={true}
        config={{ responsive: true }}
      />
    </div>
  );
};

const AgeDistributionBarChart = (props) => {
  if (props?.ageChart?.data?.[0] === '-') {
    return (
      <p></p>
    )
  }
  const AgeBarChart = [
    {
      x: props?.ageChart?.labels,
      y: props?.ageChart?.data,
      type: 'bar',
      //   name: 'Age Distribution',
      text: props?.ageChart?.data,
      textposition: 'top',
      marker: {
        color: ['#F25260', '#95ACBF', '#13C9F2', '#D9B777', '#5C59BB', '#735F46', '#333333'],
      }
    }
  ]
  return (
    <div>
      <Typography sx={{ fontFamily: 'OpenSans', color: '#888888', marginTop: '10px' }}>Age Distribution</Typography>
      <Plot
        data={AgeBarChart}
        layout={{
          width: '380',
          height: '380',
          yaxis: {
            tickvals: [],
            ticktext: []
          },
          font: {
            size: 6
          },
        }}
        useResizeHandler={true}
        config={{ responsive: true }}
      />
    </div>
  );
};
const Styles = {
  container: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    fontFamily: 'OpenSans'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    fontFamily: 'OpenSans'
    // padding:'10px'
  },
}
const CustomerDemography = (props) => {
  return (

    <Box maxWidth="md" style={Styles.container}>
      <Typography style={Styles.title}>Customer Demographics</Typography>
      <div className='graphContainer'>
        <MenWomenPieChart menWomen={props.genderParams} />
        <AgeDistributionBarChart ageChart={props.ageParams} />
      </div>
    </Box>
  );
};

export default CustomerDemography;
