const initialState = {
    ResourceName: ``
}
const ResourceDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SEARCH': {
            return Object.assign({}, state, {
                finSearch: action.value
            });
        }
        case 'SET_SEARCH': {
            return Object.assign({}, state, {
                finSearch: action.data
            });
        }
        case 'DEF_FIN': {
            return Object.assign({}, state, {
                selFin: action.data
            });
        }
        case 'GET_ALL_RESOURCES': {
            return Object.assign({}, state, {
                allResourceData: action.value
            });
        }
        case 'SET_ALL_RESOURCES': {
            return Object.assign({}, state, {
                allResourceData: action.data
            });
        }

        default: {
            return Object.assign({}, state);
        }
    }
}
export default ResourceDetails;