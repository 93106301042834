import OrgDetails from '../../components/screens/Oranizational_Overview/orgReducer';
import FinDetails from '../../components/screens/Financial/finReducer';
import loading from '../../components/container/loader/loaderReducer'
import VoiceDetails from '../../components/screens/VoiceofCustomer/voiceReducer';
import BenchmarkDetails from '../../components/screens/benchmark/benchReducer';
import DigitalDetails from '../../components/screens/DigitalResearch/digitalReducer';
import ResourceDetails from '../../components/screens/Resources/resourceReducer';
import { combineReducers } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
    AllOrgs: OrgDetails,
    loading: loading,
    AllFinData: FinDetails,
    AllVoiceData: VoiceDetails,
    AllDigitalData: DigitalDetails,
    AllBenchmark: BenchmarkDetails,
    AllResource: ResourceDetails
},
)

export default rootReducer;