import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image, Button } from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AccordionComponent from '../../container/accordion/accordion';
import KYCMenu from "../../container/menu/Menu";
import SearchComplete from "../../container/search/Search";
import kycConstants, { topOrgs } from '../../../utils/constants';
import Loader from "../../container/loader/loader";
import delete_button from '../../../assets/imgs/Landing_Page/delete_button.png'
import { Tooltip } from "@mui/material";
import { getbenchmarkCompetitorsInfo, getbenchmarkFinancialInfo, getbenchmarkCustomerSatisfactionInfo, getbenchmarkDigitalPerformanceInfo } from "./benchAction";
import CreateIcon from '@mui/icons-material/Create';
import './Benchmark.css'
import { IconButton, Typography } from "@mui/material";
import ScrollButton from "../../container/ScrollToTop/ScrollUp";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

const selectOrgstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const tableData = (companies, content) => {
    let companyContent = []
    let attributeLength = 0
    if (content && content.companies) {
        companies.forEach((comp) => {
            content.companies.forEach((item) => {
                if (comp === item.companycode) {
                    attributeLength = item.data.length
                    companyContent.push(item.data)
                }
            })
        })

    }
    let tableVal = []
    if (companyContent.length > 0) {
        companyContent.forEach((item) => {
            item.forEach((it) => {
                let row = []
                row.push(it.key)
                row.push(it.value)
                tableVal.push(row)
            })
        })
    }

    let chk = []
    let j = 0
    tableVal.forEach((item, i) => {
        let tmpArr = tableVal[j]
        if (tableVal[i + attributeLength]) {
            tmpArr.push(tableVal[i + attributeLength][1])
            chk.push(tmpArr)
            j++
            if (j >= attributeLength)
                j = 0
        }
    })
    if (chk.length === 0)
        chk = tableVal;
    let finaltable = []
    chk.forEach((item, j) => {
        if (j < attributeLength)
            finaltable.push(item)
    })
    return finaltable;

}
const Benchmark = () => {
    const dispatch = useDispatch();
    const benchmark = useSelector((state) => state.AllBenchmark);
    const orgs = useSelector((state) => state.AllOrgs);
    const [companyCode, setCompanyCode] = useState('')
    const [lastSelectedPosition, setLastSelectedPos] = useState(-1)
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [compareCompanies, setCompareCompanies] = useState([])
    const [selectComp, setSelectComp] = useState('')
    const [acc, setAcc] = useState(true);
    const clickHandler = () => {
        setAcc(false)
    }
    let selOrg = sessionStorage.getItem(`org`)
    useEffect(() => {
        if (orgs.orgsSearch && (orgs.selOrg || selOrg)) {
            orgs.orgsSearch.forEach((item) => {
                if (item.company_name === (orgs.selOrg || selOrg)) {
                    selectedCompanies.length = 0
                    setSelectedCompanies(selectedCompanies)
                    setCompanyCode(item.company_code);
                    onSelectCompany(item.company_code);
                }
            })
        }
    }, [orgs.selOrg, orgs.orgsSearch])


    useEffect(() => {
        if (companyCode) {
            dispatch(getbenchmarkFinancialInfo(companyCode));
            dispatch(getbenchmarkCompetitorsInfo(companyCode));
            dispatch(getbenchmarkCustomerSatisfactionInfo(companyCode));
            dispatch(getbenchmarkDigitalPerformanceInfo(companyCode));
        }
    }, [companyCode, dispatch])

    useEffect(() => {
        if (benchmark?.benchmarkFinancialData && benchmark?.benchmarkFinancialData.companies) {
            let compCodeArr = []
            benchmark.benchmarkFinancialData.companies.forEach((item) => {
                compCodeArr.push(item)
            })
            setCompareCompanies(compCodeArr)
        }
    }, [benchmark])

    const { instance } = useMsal();
    const location = useLocation();
    useEffect(() => {
        const msalkey = sessionStorage.getItem('msal.token.keys.9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d')
        if (!msalkey) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
        }
    }, [location, companyCode, dispatch])
    // useEffect(() => {
    //     if (benchmark?.benchmarkCustSatData && benchmark?.benchmarkCustSatData.companies) {
    //         let compCodeArr = []
    //         benchmark.benchmarkCustSatData.companies.forEach((item) => {
    //         console.log("cust sat items",item)
    //             compCodeArr.push(item)
    //         })
    //         setCompareCompanies(compCodeArr)
    //     }
    // }, [benchmark])
    // useEffect(() => {
    //     if (benchmark?.benchmarkDigPerfData && benchmark?.benchmarkDigPerfData.companies) {
    //         let compCodeArr = []
    //         benchmark.benchmarkDigPerfData.companies.forEach((item) => {
    //             console.log("dig items",item)
    //             compCodeArr.push(item)
    //         })
    //         setCompareCompanies(compCodeArr)
    //     }
    // }, [benchmark])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const onclickAdd = () => {
        setLastSelectedPos(-1)
        setShow(true)
    }
    const onclickUpdate = (indexPosition) => {
        if (indexPosition > 0) {
            setLastSelectedPos(indexPosition)
            setShow(true)
        }
    }

    // const [newComp,setNewComp] = useState([])
    // useEffect(()=>{
    //     setNewComp(companyCode)
    // },[companyCode])
    // console.log(newComp)

    const onSelectCompany = (val) => {
        setSelectComp(val)
        let comp = selectedCompanies
        if (!selectedCompanies.includes(val)) {
            if (lastSelectedPosition != -1) {
                comp.splice(lastSelectedPosition, 1, val)
            }
            else {
                comp.push(val)
            }
            setSelectedCompanies(comp)
            setShow(false)

        }
        else {
            toastr.options.positionClass = 'toast-top-center';
            toastr.options.closeButton = true;
            toastr.info("This organization is already listed");
            // alert("This organization is already listed")

        }
    }

    const onDeleteCompany = (code) => {
        if (companyCode == code) {
            toastr.info("You can't delete the selected organization")
        }
        else {
            let updatedList = selectedCompanies.filter(selectedItem => selectedItem != code)
            setSelectedCompanies(updatedList)
        }
    }

    const tryRequire = (path) => {
        try {
            return require(`../../../assets/imgs/company_logos/${companyCode}.png`);
        } catch (err) {
            return null;
        }
    };

    let DetList = selectedCompanies.map((company, index) => {
        try {
            return (
                <div className="orgcard">
                    {/* <img className="delete-button" src={delete_button} onClick={() => onDeleteCompany(company)}></img> */}
                    <a><Image
                        src={(companyCode && tryRequire(`../../../assets/imgs/company_logos/${company}.png`)) ?
                            require(`../../../assets/imgs/company_logos/${company}.png`) : ''}
                        alt={`no tagLine`}
                        className='cmpImg'
                        key={company}
                    /></a>
                </div>
            )
        }
        catch (err) {

        }
    })

    const getCurrencyFinYear = (code) => {
        let curVal = []
        benchmark?.benchmarkFinancialData?.companies.map((finCompanyItem) => {
            if (finCompanyItem.companycode == code) {
                let CompanyCurrency = getCurrencySymbol(finCompanyItem?.currency)
                const value = CompanyCurrency + " " + finCompanyItem?.currency + " | " + finCompanyItem?.financialyear
                curVal.push(value)
            }
        })
        return curVal;
    }

    const getCurrencySymbol = (props) => {
        let value = []
        if (props === 'USD') {
            value.push('$')
        }
        else if (props === 'EUR') {
            value.push('€')
        }
        else if (props === 'GBp') {
            value.push('£')
        }
        else if (props === 'CLP') {
            value.push('$')
        }
        else if (props === 'MXN') {
            value.push('$')
        }
        else if (props === 'CAD') {
            value.push('$')
        }
        return value;
    }


    return (
        <div>
            <Loader />
            <nav>
                <KYCMenu />
            </nav>
            <div className="maincontentbenchmark">
                <div className="searchbar">
                    <SearchComplete placeHolder={kycConstants.searchOrgPlcHolder}
                        topOrgs={topOrgs} />
                </div>
                <div className="benchmarkhead">
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'OpenSans' }}>Benchmarking</Typography>
                    {/* <Typography sx={{ fontSize: '14px', color: '#888888', fontFamily: 'OpenSans' }}>Data Sources: Yahoo Finance, TCS Research Report</Typography> */}
                </div>
                <div style={{ backgroundColor: "white" }}>
                    <div style={{ paddingTop: "15px", paddingBottom: "15px", backgroundColor: "#FFFFFF", display: "flex" }}>
                        <div style={{ width: "280px", height: "75px", marginLeft: "20px" }}>
                            <Typography sx={{ color: '#888888', fontWeight: 700, fontSize: '14px', fontFamily: 'OpenSans', }}>Compare Fetaures of the <br />Organisations</Typography>
                        </div>
                        {
                            selectedCompanies.map((item, index) => {
                                let updatedValue = getCurrencyFinYear(item)
                                return <div >
                                    <div style={{ width: "220px", height: "75px", marginLeft: "15px", backgroundColor: "#F5F6FB", borderRadius: "10px", cursor: 'pointer' }}>
                                        <div style={{ display: "flex" }}>
                                            <a>
                                                <Image
                                                    src={(companyCode && tryRequire(`../../../assets/imgs/company_logos/${item}.png`)) ?
                                                        require(`../../../assets/imgs/company_logos/${item}.png`) : ''}
                                                    alt={`no tagLine`}
                                                    className='benchmarkImg'
                                                    key={item}
                                                />
                                            </a>
                                            {selectedCompanies.length > 1 && index > 0 ?
                                                <div>
                                                    <Box>
                                                        <Tooltip title='Click to Select Company'>
                                                            <IconButton onClick={() => onclickUpdate(selectedCompanies.indexOf(item))}>
                                                                <CreateIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                    <div style={{ display: "inline", color: "black" }}>
                                        <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: '14px', fontFamily: 'OpenSans', marginLeft: '80px' }}>{updatedValue}</Typography>
                                    </div>

                                </div>
                            })
                        }

                        <div style={{ cursor: 'pointer' }}>
                            <div style={{ width: "220px", height: "75px", marginLeft: "15px", backgroundColor: "#F5F6FB", borderRadius: "10px", cursor: 'pointer' }} hidden={selectedCompanies.length >= 3}>
                                <Typography onClick={() => onclickAdd()} sx={{ color: '#000000', fontWeight: 700, fontSize: '18px', fontFamily: 'OpenSans', marginLeft: '10px', paddingTop: "22px" }}>+ Add an Organization</Typography>
                            </div>
                        </div>
                        <div style={{ cursor: 'pointer' }}>
                            <div style={{ width: "220px", height: "75px", marginLeft: "15px", backgroundColor: "#F5F6FB", borderRadius: "10px", cursor: 'pointer' }} hidden={selectedCompanies.length >= 2}>
                                <Typography onClick={() => onclickAdd()} sx={{ color: '#000000', fontWeight: 700, fontSize: '18px', fontFamily: 'OpenSans', marginLeft: '10px', paddingTop: "22px" }}>+ Add an Organization</Typography>
                            </div>
                        </div>


                        {/* <div>
                            <div style={{ width: "220px", height: "75px", marginLeft: "15px", backgroundColor: "#F5F6FB", borderRadius: "10px" }}>
                                <a ><Image
                                    src={(companyCode && tryRequire(`../../../assets/imgs/company_logos/AAP.png`)) ?
                                        require(`../../../assets/imgs/company_logos/AAP.png`) : ''}
                                    alt={`no tagLine`}
                                    className='benchmarkImg'
                                    key={"AAP"}

                                /></a>
                            </div>
                            <div style={{ display: "inline", color: "black", marginLeft: "20px" }}>
                                <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: '14px', font: 'OpenSans', marginTop: '12px', marginLeft: '80px' }}>$USD | FY23</Typography>
                            </div>

                        </div>
                        <div>
                            <div style={{ width: "220px", height: "75px", marginLeft: "15px", backgroundColor: "#F5F6FB", borderRadius: "10px" }}>
                                <a ><Image
                                    src={(companyCode && tryRequire(`../../../assets/imgs/company_logos/AAP.png`)) ?
                                        require(`../../../assets/imgs/company_logos/AAP.png`) : ''}
                                    alt={`no tagLine`}
                                    className='benchmarkImg'
                                    key={"AAP"}

                                /></a>
                            </div>
                            <div style={{ display: "inline", color: "black", marginLeft: "15px" }}>
                                <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: '14px', font: 'OpenSans', marginTop: '12px', marginLeft: '80px' }}>$USD | FY23</Typography>
                            </div>

                        </div>
                        <div>
                            <div style={{ width: "220px", height: "75px", marginLeft: "15px", backgroundColor: "#F5F6FB", borderRadius: "10px" }}>
                                <a ><Image
                                    src={(companyCode && tryRequire(`../../../assets/imgs/company_logos/AAP.png`)) ?
                                        require(`../../../assets/imgs/company_logos/AAP.png`) : ''}
                                    alt={`no tagLine`}
                                    className='benchmarkImg'
                                    key={"AAP"}

                                /></a>
                            </div>
                            <div style={{ display: "inline", color: "black", marginLeft: "15px" }}>
                                <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: '14px', font: 'OpenSans', marginTop: '12px', marginLeft: '80px' }}>$USD | FY23</Typography>
                            </div>

                        </div> */}


                        {/* <table className='organizationlist'>
                        <tr>
                            <td style={{ color: '#888888;', width: '20px', height: '30px', wordWrap: "break-word", whiteSpace: "nowrap" }}><h3>Compare Fetaures of the <br />Organisations</h3></td>
                            {
                                DetList.map((item) => {
                                    return (
                                        <>
                                            <td className="benchMarkIcon">{item}</td>
                                        </>
                                    )
                                })
                            }
                            <td className="benchMarkAddItemBtn">
                                <Col>
                                    <Button onClick={() => onclickAdd()} disabled={selectedCompanies.length >= 3} className="addItem"><Row><ControlPointIcon /></Row>
                                        <Row>
                                            Add Item
                                        </Row></Button></Col></td>
                        </tr>
                    </table> */}
                    </div>
                    <AccordionComponent
                        heading="Financial Performance"
                        content={tableData(selectedCompanies, benchmark?.benchmarkFinancialData)}
                        active={acc}
                        onClick={clickHandler}
                        expanded={true}
                    />
                    <AccordionComponent
                        heading="Customer Satisfaction"
                        content={tableData(selectedCompanies, benchmark?.benchmarkCustSatData)}
                        expanded={false} />
                    <AccordionComponent
                        heading="Digital Performance"
                        content={tableData(selectedCompanies, benchmark?.benchmarkDigPerfData)}
                        expanded={false}
                    />
                    {/* <AccordionComponent heading="Common Features across Channels" content="" />
            <AccordionComponent heading="Web & Mobile App Assessment" content="" /> */}
                </div>
            </div>
            <Modal
                hideBackdrop
                open={show}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{
                    ...selectOrgstyle, width: 767, height: 382, fontFamily: 'OpenSans', borderColor: 'white'
                }}>
                    <InputLabel style={{ width: "80%", height: "54px", marginLeft: "49px", color: "#000000", fontWeight: "700", fontSize: "20px" }} id="demo-simple-select-label"><h3>Add an Organization</h3></InputLabel>
                    <InputLabel style={{ width: "80%", height: "44px", marginLeft: "49px", color: "#000000", fontWeight: "400", fontSize: "14px" }} id="demo-simple-select-label">Select the organization to compare the parameters</InputLabel>

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="Select Organization"
                        value={selectComp}
                        label="Select Organisation"
                        className="selectorganizationstyle"
                        onChange={(e) => onSelectCompany(e.target.value)}
                        sx={{ fontFamily: 'OpenSans' }}
                    >
                        {compareCompanies && compareCompanies.map((item) => {
                            return (
                                <MenuItem value={item.companycode}>{item.companyname}</MenuItem>
                            )
                        })}

                    </Select>
                    {/* <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Select Org
                            <span class="caret"></span></button>
                        <ul class="dropdown-menu">
                            {compareCompanies && compareCompanies.map((item) => {
                                return (
                                    <li><a href="#" onClick={() => onSelectCompany(item)}>{item}</a></li>)
                            })}

                        </ul>
                    </div> */}
                    {/* <Button className="closeButton" onClick={handleClose}>Close</Button> */}
                    <Button className="addOrganizationButton" onClick={handleClose}>Close</Button>
                </Box>
            </Modal>

            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Select Org
                            <span class="caret"></span></button>
                        <ul class="dropdown-menu">
                            {compareCompanies && compareCompanies.map((item) => {
                                return (
                                    <li><a href="#" onClick={() => onSelectCompany(item)}>{item}</a></li>)
                            })}

                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal> */}
            <div>
                <ScrollButton />
            </div>
        </div>

    )
}

export default Benchmark;