import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import './fin.css';
import {
    getIncomeStatementYearlyInfo, getBalanceSheetYearlyInfo, getCashFlowYearlyInfo,
    getIncomeStatementQuarterlyInfo, getBalSheetQrtlyInfo, getCashFlowQrtlyInfo,
    getKeyRatioYearlyInfo, getAverageGrowthInfo, getRevenueNetprofitInfo, getPeerTableInfo
} from './finAction'
import { getOrgBulletinWindowInfo, getLatestNewsInfo } from "../Oranizational_Overview/orgAction";
import { useLocation } from 'react-router-dom';
import Bulletin from "../../container/BulletinWindow/Bulletin";
import KYCMenu from "../../container/menu/Menu";
import SearchComplete from "../../container/search/Search";
import kycConstants, { topOrgs } from '../../../utils/constants';
import Loader from "../../container/loader/loader";
import Financial from "./financial";
import ScrollButton from "../../container/ScrollToTop/ScrollUp";
import { useMsal } from "@azure/msal-react";


function FinancialOverview() {
    const dispatch = useDispatch();
    const finance = useSelector((state) => state.AllFinData);
    const orgs = useSelector((state) => state.AllOrgs);
    const [companyCode, setCompanyCode] = useState('')
    let selOrg = sessionStorage.getItem(`org`)
    useEffect(() => {
        if (orgs.orgsSearch && (orgs.selOrg || selOrg)) {
            orgs.orgsSearch.forEach((item) => {
                if (item.company_name === (orgs.selOrg || selOrg))
                    setCompanyCode(item.company_code);
            })
        }
    }, [orgs.selOrg, orgs.orgsSearch])

    useEffect(() => {
        if (companyCode) {
            dispatch(getIncomeStatementYearlyInfo(companyCode));
            dispatch(getBalSheetQrtlyInfo(companyCode));
            dispatch(getCashFlowQrtlyInfo(companyCode));
            dispatch(getKeyRatioYearlyInfo(companyCode));
            dispatch(getBalanceSheetYearlyInfo(companyCode));
            dispatch(getCashFlowYearlyInfo(companyCode));
            dispatch(getIncomeStatementQuarterlyInfo(companyCode));
            dispatch(getAverageGrowthInfo(companyCode));
            dispatch(getRevenueNetprofitInfo(companyCode));
            dispatch(getPeerTableInfo(companyCode));
            dispatch(getOrgBulletinWindowInfo(companyCode));
            dispatch(getLatestNewsInfo(companyCode))



        }
    }, [companyCode, dispatch])
    const { instance } = useMsal();
    const location = useLocation();
    useEffect(() => {
        const msalkey = sessionStorage.getItem('msal.token.keys.9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d')
        if (!msalkey) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
        }
    }, [location, companyCode, dispatch])

    return (
        <div>
            <Loader />
            <div>
                <nav>
                    <KYCMenu />
                </nav>
            </div>
            <div className="maincontent">
                <div className="searchbar">
                    <SearchComplete placeHolder={kycConstants.searchOrgPlcHolder}
                        topOrgs={topOrgs} />
                </div>
                <div className="financial">
                    <Financial
                        incomeStatement={finance?.finIncomeYearly}
                        keyRatio={finance?.finKeyRatioYly}
                        BalanceSheetYearly={finance?.finBalancesheetYearly}
                        balanceSheetQtrly={finance?.finBalSheetQly}
                        CashFlowYearly={finance?.finCashflowYearly}
                        cashFlowQtrly={finance?.finCashFlowQly}
                        IncomeStatementQuarterly={finance?.finIncomestatementQuarterly}
                        AverageGrowth={finance?.finAverageGrowth}
                        RevenueNetprofit={finance?.finRevenueNetprofit}
                        finPeerTable={finance?.finPeerTable}
                        currencyData={orgs.orgsBulletinWindow}
                    />
                </div>
            </div>
            <div className="sidebar">
                <div className="finbull" >
                    <Bulletin data={orgs.orgsBulletinWindow} news={orgs.latestNews} />
                </div>
            </div>
            <ScrollButton />
        </div>
    )
}

export default FinancialOverview;