
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../Msal/authConfig";
import { Button } from "@mui/material";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <Button variant="contained" style={{ backgroundColor: '#2924A6', fontFamily: 'openSans', height: '60px', width: '110px', borderRadius: '10px' }} onClick={() => handleLogin("redirect")}>Sign In</Button>
    )
}
export default SignInButton;