export const orgs = {
    url: `https://testcoe1.azurewebsites.net/api/landing-page/getallcompanydetails`,
    method: `GET`
}

export const latestNews = {
    url: `https://testcoe1.azurewebsites.net/api/landing-page/getlatestnews`,
    method: `GET`
}
export const orgsDescription = {
    url: `https://testcoe1.azurewebsites.net/api/organizational-overview/getcompanydescription`,
    method: `GET`
}

export const orgsFlexbox = {
    url: `https://testcoe1.azurewebsites.net/api/organizational-overview/getorganizationdata`,
    method: `GET`
}
export const orgsKeyExecutives = {
    url: `https://testcoe1.azurewebsites.net/api/organizational-overview/getkeyexecutivesdata`,
    method: `GET`
}

export const orgsMarginAnalysis = {
    url: `https://testcoe1.azurewebsites.net/api/organizational-overview/getmarginalanalysisdata`,
    method: `GET`
}

export const orgsKeyRatiosData = {
    url: `https://testcoe1.azurewebsites.net/api/organizational-overview/getfinancialsnapshot`,
    method: `GET`
}

export const orgsBulletinWindow = {
    url: `https://testcoe1.azurewebsites.net/api/landing-page/bulletinwindowdata`,
    method: `GET`
}

export const orgsKeyCompetitors = {
    url: `https://testcoe1.azurewebsites.net/api/organizational-overview/getkeycompetitorsdata`,
    method: `GET`
}
export const orgsKeyStrategy = {
    url: `https://testcoe1.azurewebsites.net/api/organizational-overview/getkeystrategy`,
    method: `GET`
}

export const finIncomeStatementYearly = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getincomestatementyearly`,
    method: `GET`
}

export const finBalanceSheetQuarterly = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getbalancesheetquarterly`,
    method: `GET`
}

export const finCashFlowQuarterly = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getcashflowquarterly`,
    method: `GET`
}

export const finKeyRatioYearly = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getkeyratiosdatayearly`,
    method: `GET`
}

export const finbalanceSheetYearly = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getbalancesheetyearly`,
    method: `GET`
}

export const finCashFlowYearly = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getcashflowyearly`,
    method: `GET`
}

export const finIncomeStatementQuarterly = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getincomestatementquarterly`,
    method: `GET`
}

export const finAveragegrowth = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getaveragegrowthrates`,
    method: `GET`
}
export const finPeerTable = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getpeervaluationdata`,
    method: `GET`
}

export const finRevenueNetprofit = {
    url: `https://testcoe1.azurewebsites.net/api/financial-overview/getrevenueandnetprofit`,
    method: `GET`
}


export const voiceCustParams = {
    url: `https://testcoe1.azurewebsites.net/api/voice-of-customer/getvoiceofcustomerparam`,
    method: `GET`
}
export const voiceGenderParams = {
    url: `https://testcoe1.azurewebsites.net/api/voice-of-customer/getgenderdistribution`,
    method: `GET`
}
export const voiceAgeParams = {
    url: `https://testcoe1.azurewebsites.net/api/voice-of-customer/getagedistribution`,
    method: `GET`
}
export const voicePosParams = {
    url: `https://testcoe1.azurewebsites.net/api/voice-of-customer/getpositivehighlights`,
    method: `GET`
}
export const voiceCriticalParams = {
    url: `https://testcoe1.azurewebsites.net/api/voice-of-customer/getnegativehighlights`,
    method: `GET`
}
export const voiceWebParams = {
    url: `https://testcoe1.azurewebsites.net//api/voice-of-customer/getscrapinginformation`,
    method: `GET`
}

export const voiceWordCloudParams = {
    url: `https://testcoe1.azurewebsites.net/api/voice-of-customer/getwordcloudimage`,
    method: `GET`
}


export const digitalOverallTrafficParams = {
    url: `https://testcoe1.azurewebsites.net/api/digital-overview/getoveralltraffic`,
    method: `GET`
}

export const digitalGraphDataParams = {
    url: `https://testcoe1.azurewebsites.net/api/digital-overview/digitalresearchgraphsdata`,
    method: `GET`
}


export const benchmarkingCompetitorParams = {
    url: `https://testcoe1.azurewebsites.net/api/benchmarking/getkeycompetitorslist`,
    method: `GET`
}

export const benchmarkFinancialParams = {
    url: `https://testcoe1.azurewebsites.net/api/benchmarking/getfinancialperformance`,
    method: `GET`
}

export const benchmarkCustomerSatisfactionParams = {
    url: `https://testcoe1.azurewebsites.net/api/benchmarking/getcustomersatisfaction`,
    method: `GET`
}

export const benchmarkDigitalPerformanceParams = {
    url: `https://testcoe1.azurewebsites.net/api/benchmarking/getdigitalperformance`,
    method: `GET`
}

export const getAllResources = {
    url: `https://testcoe1.azurewebsites.net/api/resources`,
    method: `GET`
}

