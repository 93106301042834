import { useState } from 'react';
import { IconButton, Modal, Box, Typography } from '@mui/material';
import { Close, Fullscreen } from '@mui/icons-material';
import no_image from '../../../assets/imgs/Voice_of_Cust/no_image.png'

function StockExchangeChart() {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(true);
    };

    const handleCloseClick = () => {
        setExpanded(false);
    };

    return (
        <div style={Styles.imageContainer}>
            <Typography style={Styles.title}>Stock Exchange Chart</Typography>
            <img style={Styles.image} src={no_image} onClick={handleExpandClick} />
            <IconButton style={Styles.expandButton} onClick={handleExpandClick}>
                <Fullscreen />
            </IconButton>
            <Modal open={expanded} onClose={handleCloseClick}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    onClick={handleCloseClick}
                >
                    <IconButton style={Styles.closeButton}>
                        <Close />
                    </IconButton>
                    <img src={no_image} alt='LOADING' />
                </Box>
            </Modal>
        </div>
    );
}

const Styles = {
    imageContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        cursor: 'pointer',
    },
    expandButton: {
        position: 'absolute',
        top: '-10px',
        right: '-6px',
        color: 'blue',
    },
    closeButton: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        color: 'black',
        zIndex: 1,
    },
    title: {
        fontWeight: 700,
        marginBottom: '15px'
    }
}
export default StockExchangeChart;