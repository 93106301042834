import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { menuItems } from '../../../utils/constants';
// import {Nav, NavLink} from 'react-router-dom';
import './Menu.css';
import { useNavigate } from 'react-router-dom';
import kyc_menu_logo from '../../../assets/imgs/Menu/kyc_menu_logo.png'
import { Button, Grid } from '@mui/material';
import DownloadApplication from '../DownloadApp/AppDownload'
import LogoutButton from '../Login/Logout';
import { useLocation } from 'react-router-dom';


const KYCMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (url) => {
        return (
            location.pathname.includes(url) ||
            (url === '/fin/summary' && location.pathname.startsWith('/fin/'))
        );
    };
    const Styles = {
        container: {
            // maxWidth: '600px',
            // margin: '0 auto',
            backgroundColor: 'white',
            width: '190px',
            fontFamily: 'OpenSans'
        },
    }
    return (
        <div style={Styles.container}>
            <div className='tcscore_logo'>
                <a href='/'><img className='logo' src={kyc_menu_logo} alt={`no tagLine`} /></a>
            </div>
            {menuItems.map((menu) => <div>
                <Row className='topMenu'>
                    <Col>
                        <span className="menuLabels"
                            style={
                                isActive(menu.url)
                                    ? {
                                        backgroundColor: '#2924A6',
                                        color: 'white',
                                        borderRadius: '0px 10px 10px 0px',
                                        padding: '10px',
                                        width: '100%',
                                        display: 'inline-block',
                                    }
                                    : { backgroundColor: 'white' }
                            }
                            onClick={() => navigate(menu.url)} sx={{ fontFamily: 'OpenSans' }}>{menu.title}</span>
                    </Col>
                </Row>

            </div>)}
            <div style={{ marginTop: '20px' }}>

                {/* <DownloadApplication /> */}
            </div>
            <LogoutButton />
        </div>
    )
}
export default KYCMenu;



