import logo from './logo.svg';
import './App.css';
import route from './routes/index';
import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import SignIn from './components/container/Login/LoginScreen';
import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from 'react';
import MobileNotification from './components/container/MobileNotification/MobileNotification';
function App() {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const handleLogout = () => {
      sessionStorage.clear()
    };
    const broadcastChannel = new BroadcastChannel('logoutChannel');
    broadcastChannel.addEventListener('message', (event) => {
      if (event.data === 'logout') {
        handleLogout();
      }
    });
    return () => {
      broadcastChannel.removeEventListener('message', handleLogout);
    };
  }, [])

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {route}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn />
      </UnauthenticatedTemplate>

      {/* {isAuthenticated ? <Navigate to='/org' /> : <SignIn />} */}
    </div>
  );
}

export default App;