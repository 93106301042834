export const DEF_FIN=`DEF_FIN`;

export const GET_SEARCH ='GET_SEARCH';
export const SET_SEARCH ='SET_SEARCH';

export const GET_OVERALL_TRAFFIC ='GET_OVERALL_TRAFFIC';
export const SET_OVERALL_TRAFFIC ='SET_OVERALL_TRAFFIC';

export const GET_GRAPH_DATA ='GET_GRAPH_DATA';
export const SET_GRAPH_DATA ='SET_GRAPH_DATA';

export const setDefFin=(data)=>({
    type:DEF_FIN,
    data
})


export const getFinSearchInfo =()=>({type:GET_SEARCH});

export const setFinSearchInfo=(data)=>({
    type:SET_SEARCH,
    data
})

export const getOverallTraficInfo =(params)=>({type:GET_OVERALL_TRAFFIC,companyCode:params});

export const setOverallTraficInfo=(data)=>({
    type:SET_OVERALL_TRAFFIC,
    data
})

export const getGraphDataInfo =(params)=>({type:GET_GRAPH_DATA,companyCode:params});

export const setGraphDataInfo=(data)=>({
    type:SET_GRAPH_DATA,
    data
})