import React from 'react';
import {Row,Col} from 'react-bootstrap';
import '../newsFeed/NewsFeed.css'

const NewsFeed = () => {

    const News = { News: `Lorem Ipsum has been the industry's standard dummy text ever since 1500's
    Lorem Ipsum has been the industry's standard dummy text ever since 1500s.
    Lorem Ipsum has been the industry's standard dummy text ever since 1500s.` }
    return (
        <>
            <Row className='NewsFeed'>
                <Col>
                    <div className='NewsFeed_Card'>
                        {News.News}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default NewsFeed