import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Plot from 'react-plotly.js';

const BarChart = (props) => {

  const Styles = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    padding: '10px',
  }
  if (props?.data?.data?.[0] === '-') {
    return (
      <Typography sx={{ fontFamily: 'OpenSans', marginBottom: '20px', textAlign: 'center' }}>No data available</Typography>
    )
  }
  const data = [
    {
      x: props.data?.labels,
      y: props.data?.data,
      type: 'bar',
      // width: 0.6,
      text: props.data?.data,
      textposition: 'top',
      marker: {
        color: ['#F25260', '#95ACBF', '#13C9F2', '#D9B777', '#5C59BB', '#735F46', '#333333'],
      }
    }
  ];
  const layout = {
    yaxis: {
      tickvals: [],
      textvals: []
    },

  }

  return (
    <>
      <Plot
        data={data}
        useResizeHandler={true}
        config={{ responsive: true }}
        layout={layout}
        style={{ width: '100%', height: '100%', fontFamily: 'OpenSans' }}
      />
    </>
  );
};

export default BarChart;


