import React from 'react';
import './dynamicTable.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

let params = ['Revenue', 'Margin', 'Payables']

let formatTableContent = (params, content) => {
    let res = { data: [] }
    let key = Object.keys(content)
    params.forEach(element => {
        let row = []
        row.push(element)
        key.forEach(ele => {
            let org = ele
            row.push(content[org][element])
        })
        res.data.push(row)
    })
    return res;
}


let keyRatioContent = (data) => {
    let header = ['Key Ratios']
    data.headers.forEach((item) => {
        let val = item.toString().replace('20', 'FY')
        header.push(val)
    })
    let tableData = []
    let keyRatios = []
    data.data.forEach((item, i) => {
        keyRatios.push(item.metric_name)
    })
    var filteredArray = keyRatios.filter(function (item, pos) {
        return keyRatios.indexOf(item) == pos;
    });
    filteredArray.forEach((it) => {
        let tableRow = [it]
        data.data.forEach((item, i) => {
            if (item.metric_name === it)
                tableRow.push(item.metric_value)

        })
        tableData.push(tableRow)
    })
    return { data: tableData, header };
}
// const Styles = {
//     container: {
//         maxWidth: '100%',
//         margin: '0 auto',
//         backgroundColor: 'white',
//         borderRadius: '10px',
//         paddingStart: '20px',
//         paddingEnd: '20px',
//         fontFamily: 'OpenSans'
//     },
//     border: {
//         borderBottom: '1.5px solid #ccc'
//     },
//     cellstyle: {
//         width: '28%'
//     }
// }
// const DynamicTable = (props) => {
//     return (
//         <div style={Styles.container}>
//             <TableContainer>
//                 <Table>
//                     {
//                         props?.data.header && props?.data.header.map((item) => {
//                             return (
//                                 <TableHead sx={{
//                                     fontFamily: 'OpenSans'
//                                 }}>{item}</TableHead>
//                             )
//                         })
//                     }
//                     {
//                         props?.data.map((item) => {
//                             return (
//                                 <TableBody>
//                                     <TableRow style={Styles.border}>
//                                         {
//                                             item.map((val) => {
//                                                 return (
//                                                     <TableCell style={Styles.cellstyle} sx={{
//                                                         fontFamily: 'OpenSans'
//                                                     }}>{val}</TableCell>

//                                                 )
//                                             })
//                                         }
//                                     </TableRow>
//                                 </TableBody>
//                             )
//                         })
//                     }
//                 </Table>
//             </TableContainer>
//         </div>
//     )
// }

// export default DynamicTable;

const Styles = {
    container: {
        maxWidth: '100%',
        margin: '0 auto',
        backgroundColor: 'white',
        borderRadius: '10px',
        paddingStart: '20px',
        paddingEnd: '20px',
        fontFamily: 'OpenSans'
    },
    border: {
        borderBottom: '1.5px solid #ccc'
    },
    cellstyle: {
        width: '20%' // set width for first cell
    },
    cellstyle2: {
        width: '20%' // set width for second cell
    },
    cellstyle3: {
        width: '20%' // set width for third cell
    },
    cellstyle4: {
        width: '25%' // set width for fourth cell
    }
}

const DynamicTable = (props) => {
    return (
        <div style={Styles.container}>
            <TableContainer sx={{ boxShadow: 'none' }}>
                <Table>
                    {
                        props?.data.header && props?.data.header.map((item) => {
                            return (
                                <TableHead sx={{
                                    fontFamily: 'OpenSans', textAlign: 'left'
                                }}>{item}</TableHead>
                            )
                        })
                    }
                    {
                        props?.data.map((item) => {
                            return (
                                <TableBody>
                                    <TableRow style={Styles.border}>
                                        <TableCell style={Styles.cellstyle} sx={{
                                            fontFamily: 'OpenSans', textAlign: 'left'
                                        }}>{item[0]}</TableCell>
                                        <TableCell style={Styles.cellstyle2} sx={{
                                            fontFamily: 'OpenSans', textAlign: 'right'
                                        }}>{item[1]}</TableCell>
                                        <TableCell style={Styles.cellstyle3} sx={{
                                            fontFamily: 'OpenSans', textAlign: 'right'
                                        }}>{item[2]}</TableCell>
                                        <TableCell style={Styles.cellstyle4} sx={{
                                            fontFamily: 'OpenSans', textAlign: 'right', paddingRight: '60px'
                                        }}>{item[3]}</TableCell>
                                    </TableRow>
                                </TableBody>
                            )
                        })
                    }
                </Table>
            </TableContainer>
        </div>
    )
}

export default DynamicTable;
