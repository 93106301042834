import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Bulletin from "../../container/BulletinWindow/Bulletin";
import KYCMenu from "../../container/menu/Menu";
import ScrollButton from "../../container/ScrollToTop/ScrollUp";
import { Typography } from "@mui/material";
import kycConstants, { topOrgs } from '../../../utils/constants';
import Loader from "../../container/loader/loader";
import SearchComplete from "../../container/search/Search";
import {
    getOrgInfo,
    getOrgBulletinWindowInfo, getLatestNewsInfo
} from '../Oranizational_Overview/orgAction'
import { getallResourcesInfo } from "./resourceAction";
// import ResourceTable from "../../container/Resource Table/ResourceTable";
import './Resource.css';
// import EnhancedTable from "../../container/Resource Table/ResourceTable";
// import DataTable from "../../container/Resource Table/DemoTable";
import ResourceTable from "../../container/Resource Table/Table";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

const Resource = () => {
    const dispatch = useDispatch();
    const resources = useSelector((state) => state.AllResource);
    const orgs = useSelector((state) => state.AllOrgs);
    const [companyCode, setCompanyCode] = useState('')
    const [resourceData, setResourceData] = useState(resources?.allResourceData?.resources);

    let selOrg = sessionStorage.getItem(`org`)
    useEffect(() => {
        if (orgs.orgsSearch && (orgs.selOrg || selOrg)) {
            orgs.orgsSearch.forEach((item) => {
                if (item.company_name === (orgs.selOrg || selOrg))
                    setCompanyCode(item.company_code);
            })
        }
    }, [orgs.selOrg, orgs.orgsSearch])

    useEffect(() => {

        if (companyCode) {
            dispatch(getOrgInfo(companyCode));
            dispatch(getOrgBulletinWindowInfo(companyCode));
            dispatch(getLatestNewsInfo(companyCode))
            dispatch(getallResourcesInfo(companyCode))
        }
    }, [companyCode, dispatch])

    const { instance } = useMsal();
    const location = useLocation();
    useEffect(() => {
        const msalkey = sessionStorage.getItem('msal.token.keys.9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d')
        if (!msalkey) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
        }
    }, [location, companyCode, dispatch])

    useEffect(() => {
        setResourceData(resources?.allResourceData?.resources);
    }, [resources?.allResourceData?.resources]);



    // console.log(Resource?.allResourceData?.resources?.length, 'orgs new')
    return (
        <div >
            <Loader />
            <div>
                <nav>
                    <KYCMenu />
                </nav>
            </div>
            <div>
                <div className="maincontent">
                    <div className="searchbar">
                        <SearchComplete placeHolder={kycConstants.searchOrgPlcHolder}
                            topOrgs={topOrgs} />
                    </div>

                    <div style={{ marginLeft: '20px' }}>
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'OpenSans' }}>Resources</Typography>
                        <Typography sx={{ fontSize: '14px', color: '#888888', fontFamily: 'OpenSans' }}>All Files</Typography>
                    </div>

                    {/* <div className="resource-table">
                        <EnhancedTable companyCode={companyCode} data={Resource.allResourceData} />
                    </div> */}

                    {/* <div className="resource-table">
                        <DataTable companyCode={companyCode} data={Resource.allResourceData} />
                    </div> */}

                    <div className="resource-table">
                        <ResourceTable companyCode={companyCode} data={resourceData} />
                    </div>
                </div>


            </div>
            <div className="sidebar">
                <div className="orgbull" >
                    <Bulletin data={orgs.orgsBulletinWindow} news={orgs.latestNews} />
                </div>
                <ScrollButton />
            </div>
        </div>
    )
}

export default Resource
