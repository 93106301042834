import React, { useState } from 'react';
import { Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import './AddResorce.css'
import { useDispatch, useSelector } from 'react-redux';
import { getallResourcesInfo } from '../../screens/Resources/resourceAction';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';



function AddResponse(props) {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [Doc_type, setDocType] = useState('');
    const [file, setFile] = useState(null);

    const allowedFileTypes = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
    const dispatch = useDispatch();

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleOwnerChange = (event) => {
        setDocType(event.target.value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && allowedFileTypes.includes(file.type)) {
            setFile(file);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        toastr.options.positionClass = 'toast-top-center';
        toastr.options.progressBar = true;
        toastr.options.closeButton = true;
        toastr.info("uploading the file")
        const formData = new FormData();
        formData.append('file', file);
        formData.append('owner_name', Doc_type);
        formData.append('title', title);


        try {
            const response = await axios.post(`https://testcoe1.azurewebsites.net/api/resources/${props?.code}/uploadfile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response?.data?.error === 'File already exists') {
                console.log(response.data, 'SUCESS');
                // alert(response?.data?.error);
                toastr.clear()
                toastr.options.positionClass = 'toast-top-center';
                toastr.options.progressBar = false;
                toastr.options.closeButton = true;
                toastr.warning('File already exists');
            }
            else if (response?.data?.message === 'File uploaded successfully') {
                // alert(response?.data?.message)
                toastr.clear()
                toastr.options.positionClass = 'toast-top-center';
                toastr.options.progressBar = false;
                toastr.options.closeButton = true;
                toastr.success('File uploaded successfully!');
            }
            dispatch(getallResourcesInfo(props?.code))
            handleClose();
        } catch (error) {
            console.error(error);
            alert.error(error)
        }
    };

    return (
        <div className='resorce-container'>
            {/* <div className="org_head">
                <Typography sx={{ fontWeight: 'bold', fontFamily: 'OpenSans' }}>Resources</Typography>
                <Typography sx={{ fontSize: '14px', color: '#888888', fontFamily: 'OpenSans' }}>All Files</Typography>
            </div> */}

            <Button variant="contained" style={{ backgroundColor: '#2924A6', minWidth: '128px', minWidth: '128px', maxHeight: '36px', minHeight: '30px' }} onClick={handleClickOpen}>
                Add New File <AddIcon fontSize='small' />
            </Button>
            <Dialog open={open} onClose={handleClose} className='dialog' sx={{ height: '600px' }}>
                <div style={{ display: 'flex' }}>
                    <DialogTitle sx={{ fontWeight: 700 }}>Add New File</DialogTitle>
                    <IconButton aria-label="close" onClick={handleClose} sx={{ alignItems: 'center', marginLeft: '60%' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent sx={{ width: '500px', }}>
                    <Typography variant="subtitle2">Title</Typography>
                    <TextField value={title} onChange={handleTitleChange} fullWidth />
                    {/* <Typography variant="subtitle2">Owner name</Typography>
                    <TextField value={owner_name} onChange={handleOwnerChange} fullWidth /> */}
                    <Typography variant="subtitle2">Report Type</Typography>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
                        <Select value={Doc_type} onChange={handleOwnerChange} fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Document Type">
                            <MenuItem value="Quarterly report">Quarterly report</MenuItem>
                            <MenuItem value="Annual report">Annual report</MenuItem>
                            <MenuItem value="Corporate research report">Corporate research report</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography variant="subtitle2">Attach Documents</Typography>
                    <TextField

                        type="file"
                        inputProps={{
                            accept: '.pdf,.ppt,.pptx',
                            onChange: handleFileChange,
                            onDrop: (event) => {
                                event.preventDefault();
                                const file = event.dataTransfer.files[0];
                                if (file && allowedFileTypes.includes(file.type)) {
                                    setFile(file);
                                }
                            },
                            onDragOver: (event) => {
                                event.preventDefault();
                            }
                        }}
                        fullWidth
                    />
                    <Typography variant="subtitle2">Accepted File Types: pdf, ppt, pptx</Typography>

                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Cancel</Button> */}
                    <Button onClick={handleSave} variant="contained" style={{ backgroundColor: '#2924A6' }}>Upload</Button>  {/*disabled={!title || !owner_name || !file}*/}
                </DialogActions>

            </Dialog>
        </div>
    );
}

export default AddResponse;
