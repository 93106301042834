import React, { useState, useEffect } from 'react';
import './organization.css';


const Organizationalbox = (props) => {
  const compCurrency = props.data?.company_currency
  const [currency, setCurrency] = useState('')
  useEffect(() => {
    if (compCurrency === 'USD') {
      setCurrency('$')
    }
    else if (compCurrency === 'EUR') {
      setCurrency('€')
    }
    else if (compCurrency === 'GBp') {
      setCurrency('£')
    }
    else if (compCurrency === 'CLP') {
      setCurrency('$')
    }
    else if (compCurrency === 'CAD') {
      setCurrency('$')
    }
    else if (compCurrency === 'MXN') {
      setCurrency('$')
    }
    else if (compCurrency === 'JPY') {
      setCurrency('¥')
    }
  }, [compCurrency])

  const [revenue, setrevenue] = useState("")
  useEffect(() => {
    if (props.bullet) {
      props.bullet.forEach(element => {
        if (element.title === "Revenue") {
          setrevenue(element.value)
        }
      });
    }
    else {
      setrevenue("")
    }
  }, [props.bullet])

  return (
    <div className='flex-main-container'>
      <div className='flex-title'>
        <h3>Key Values</h3>
        <h9 style={{ color: 'black' }}>{props.data?.financial_year}</h9>
      </div>
      <div class="flex-container">
        <div class="card"><p>Revenue</p><h3>{currency}{revenue}</h3></div>
        <div class="card"><p>Market Cap</p><h3>{currency}{props.data?.marketcap}</h3></div>
        <div class="card"><p>P/E Ratio</p><h3>{props.data?.p_by_e_ratio}</h3></div>
        <div class="card"><p>Operating Margin</p><h3> {props.data?.operating_margin} %</h3></div>
        <div class="card"><p>Debt/Equity Ratio</p><h3>{props.data?.de_ratio}</h3></div>
        <div class="card"><p>Shares Outstanding</p><h3>{props.data?.shares_outstanding}</h3></div>
      </div>
    </div>

  );
};

export default Organizationalbox;
