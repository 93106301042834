import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Backdrop } from "@mui/material";
import "./loader.css";
import { Circles } from "react-loader-spinner";

/**
 * @component Loader
 * component to implement loading during APi calls
 * or any other operation that takes some time
 * @param {loader} props
 */
const Loader = (props) => {
  const loaderFlag = useSelector((state) => state.loading.isLoading);

  return (
    <div>
      <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderFlag}
            >
                {/* <div>
                    <Spinner animation="border" role="status" />
                </div> */}
                        <Circles
                height="80"
                width="80"
                color="#2924A6"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
                {/* <div className="message">Loading</div> */}
            </Backdrop>

     
    </div>
  );
};

export default Loader;
