// import { Typography, Collapse, Link } from '@mui/material';
// import { useEffect, useState } from 'react';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import no_data_img from '../../../assets/imgs/Landing_Page/no_data_img.png'

// const Description = (props) => {
//   // console.log('description', props?.KeyStratergy)
//   const description = props?.KeyStratergy?.[0]?.keystrategy.split('\n')
//   console.log('key', description)
//   const location = props?.KeyStratergy?.[0]?.location
//   const [expanded, setExpanded] = useState(false);
//   const [displayedDescription, setDisplayedDescription] = useState('');

//   const shouldTruncate = description?.length > 500;
//   const truncatedDescription = shouldTruncate
//     ? `${description.substring(0, 500)}...`
//     : description;

//   const handleExpandClick = () => {
//     setExpanded(!expanded);

//     if (!displayedDescription) {
//       setDisplayedDescription(description);
//     } else if (!expanded) {
//       setDisplayedDescription(description);
//     } else {
//       setDisplayedDescription('');
//     }
//   };

//   // const renderedDescription = expanded ? description : displayedDescription || truncatedDescription;
//   const renderedDescription = description === "-"
//     ?
//     <div>
//       <img className='logo' src={no_data_img} alt={`no tagLine`} class="center" style={{ height: '40%', width: '40%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
//       <Typography sx={{ textAlign: 'center', padding: '20px', fontFamily: 'OpenSans' }}>Please visit the source page to learn more about the Key strategy</Typography>
//     </div>
//     : expanded ? description : displayedDescription || truncatedDescription;


//   return (
//     <div style={styles.container}>
//       <Typography variant="body1" component="p" style={styles.description}>
//         <Typography style={styles.title}>Key Strategy</Typography>
//         {renderedDescription}
//         {shouldTruncate && (
//           <Typography
//             variant="body2"
//             component="span"
//             style={styles.expand}
//             onClick={handleExpandClick}
//           >
//             {expanded ? ' Read less' : ' Read more'}
//           </Typography>
//         )}

//         <Typography >
//           <Link sx={{ display: 'flex', color: '#2924A6' }} href={location} underline="hover" target="_blank" rel="noopener noreferrer">Navigate to source location
//             <OpenInNewIcon />
//           </Link>
//         </Typography>
//       </Typography>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     maxWidth: '800px',
//     margin: '0 auto',
//   },
//   description: {
//     fontSize: '16px',
//     lineHeight: '1.8',
//     marginBottom: '20px',
//     backgroundColor: 'white',
//     borderRadius: '10px',
//     textAlign: 'justify',
//     padding: '20px',
//     fontFamily: 'OpenSans'
//   },
//   expand: {
//     cursor: 'pointer',
//     // textDecoration: 'underline',
//     color: '#2924A6',
//   },
//   title: {
//     fontWeight: 'bold',
//     fontSize: '18px',
//     color: '#2924A6',
//     fontFamily: 'OpenSans'
//   },
// };
// export default Description;


import { Typography, Link } from '@mui/material';
import { useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import no_data_img from '../../../assets/imgs/Landing_Page/no_data_img.png';

const KeyStratergy = (props) => {
  const keyStrategyData = props?.KeyStratergy?.[0]?.keystrategy?.replace('.\n', '..b')
  const lines = keyStrategyData?.split('.b');
  const location = props?.KeyStratergy?.[0]?.location
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  if (keyStrategyData === '-') {
    return (
      <div style={styles.container}>
        <Typography variant="body1" component="p" style={styles.description}>
          <Typography style={styles.title}>Key Strategy</Typography>
          <Typography sx={{ textAlign: 'center', padding: '20px', fontFamily: 'OpenSans' }}>Please visit the source page to learn more about the Key strategy</Typography>
          <Typography >
            <Link sx={{ display: 'flex', color: '#2924A6' }} href={location} underline="hover" target="_blank" rel="noopener noreferrer">Navigate to source location
              <OpenInNewIcon />
            </Link>
          </Typography>
        </Typography>


      </div>
    )
  }
  return (
    <div style={styles.container}>
      <Typography variant="body1" component="p" style={styles.description}>
        <Typography style={styles.title}>Key Strategy</Typography>
        {showAll ? (
          <div>
            {lines?.map((line, index) => (
              <div key={index}>{line}</div>
            ))}

          </div>
        ) : (
          <div>
            {lines?.slice(0, 1).map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </div>
        )}

        {lines?.length > 1 && (
          <div>
            <div onClick={toggleShowAll} style={styles.expand}>
              {showAll ? "Read less" : "Read more"}
            </div>
          </div>
        )}
        <Typography >
          <Link sx={{ display: 'flex', color: '#2924A6' }} href={location} underline="hover" target="_blank" rel="noopener noreferrer">Navigate to source location
            <OpenInNewIcon />
          </Link>
        </Typography>
      </Typography>
    </div>
  );
};
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  description: {
    fontSize: '16px',
    lineHeight: '1.8',
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    textAlign: 'justify',
    padding: '20px',
    fontFamily: 'OpenSans'
  },
  expand: {
    cursor: 'pointer',
    // textDecoration: 'underline',
    color: '#2924A6',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    fontFamily: 'OpenSans'
  },
};
export default KeyStratergy