import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { sortArray, metricName } from "../../../utils/utils";

const Styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    fontFamily: 'OpenSans'
  },
  tableContainer: {
    maxWidth: "100%",
    margin: "20px 0",
    border: "1px solid #ccc",
    borderRadius: "5px",
    overflow: "auto",
  },
  table: {
    minWidth: 650,
    backgroundColor: "white",
  },
  headerCell: {
    fontWeight: "bold",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    textAlign: "left",
    fontFamily: 'OpenSans'
  },
  dataCell: {
    padding: "10px",
    textAlign: "left",
    backgroundColor: "white",
    fontFamily: 'OpenSans'
  },
  underline: {
    borderBottom: "1px solid #ccc",
  },
  title: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#2924A6",
    padding: "10px",
    fontFamily: 'OpenSans'
  },
};

function KeyRatioTable(props) {
  const compCurrency = props?.currencyData?.company_currency;
  const [currency, setCurrency] = useState("");
  useEffect(() => {
    if (compCurrency === "USD") {
      setCurrency("$");
    } else if (compCurrency === "EUR") {
      setCurrency("€");
    } else if (compCurrency === "GBp") {
      setCurrency("£");
    } else if (compCurrency === "CLP") {
      setCurrency("$");
    } else if (compCurrency === "CAD") {
      setCurrency("$");
    } else if (compCurrency === "MXN") {
      setCurrency("$");
    }
    else if (compCurrency === "JPY") {
      setCurrency("¥");
    }


  }, [compCurrency]);

  const tableData = props?.data?.data;
  const order = [
    "Total Revenue",
    "EBITDA Margin",
    "Net Profit Margin",
    "Diluted EPS",
    "Free Cash Flow Per Share",
  ];

  sortArray(order, tableData)


  return (
    <div style={Styles.container}>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Typography style={Styles.title}>Financial Snapshot</Typography>
        <Table style={Styles.table} aria-label="key ratios table">
          <TableHead>
            <TableRow>
              <TableCell style={{ ...Styles.headerCell, textAlign: 'left' }}>
                Key Performance Indicator
              </TableCell>
              {props.data?.headers &&
                props.data.headers.map((header, index) => (
                  <TableCell
                    key={index}
                    style={{ ...Styles.headerCell, textAlign: 'right' }}
                    align="center"
                  >
                    {header}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data?.data &&
              props.data.data.map((row, index) => (
                <TableRow key={index} style={Styles.underline}>
                  <TableCell component="th" scope="row" style={{ ...Styles.dataCell, textAlign: 'left' }}>
                    {metricName(row.metric_name, order)}
                  </TableCell>
                  {row.values.map((cell, index) => (
                    <TableCell
                      key={index}
                      style={{ ...Styles.dataCell, textAlign: 'right' }}
                      align="center"
                    >
                      {(row.metric_name === "Diluted EPS" ||
                        row.metric_name === "Total Revenue" ||
                        row.metric_name === "Free Cash Flow Per Share") &&
                        (" " + currency)}
                      {cell}
                      {(row.metric_name === 'EBITDA Margin' ||
                        row.metric_name === 'Net Profit Margin') && ' %'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default KeyRatioTable;