import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tcsLogo from '../../../assets/imgs/tcsLogo.svg';
import core_main from '../../../assets/imgs/Landing_Page/core_main.png';
import { Row, Col, Image } from 'react-bootstrap';
import logoTagline from '../../../assets/imgs/logoTagline.svg';
import kycConstants, { topOrgs } from '../../../utils/constants';
import SearchComplete from '../../container/search/Search';
import './Home.css';
import Loader from '../../container/loader/loader';
import { setDefOrg } from '../../screens/Oranizational_Overview/orgAction';
import { Navigate } from 'react-router-dom';
import know_your_customer from '../../../assets/imgs/Landing_Page/know_your_customer.png'
import kyc_logo_white from '../../../assets/imgs/Landing_Page/kyc_logo_white.png'
import { Typography, Box } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import MobileNotification from '../../container/MobileNotification/MobileNotification';

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orgs = useSelector((state) => state.AllOrgs.orgsSearch);
    const onImageClick = (company) => {
        if (orgs && company.companyName) {
            orgs.forEach((item) => {
                if (item.company_code === company.companyName)
                    dispatch(setDefOrg(item.company_name));
            })
        }
        navigate('/org')
    }

    const companyList = [{ companyName: `AAP`, url: ``, name: 'Advanced Auto Parts' }, { companyName: `GPC`, url: ``, name: 'Genuine Parts Company' }, { companyName: `AAP`, url: ``, name: 'Advanced Auto Parts' }, { companyName: `GPC`, url: ``, name: 'Genuine Parts Company' },
    ];
    let DetList = companyList.map((company, index) => {
        return (<a ><div className='img_div'>
            <Image
                src={require(`../../../assets/imgs/${company.companyName}.svg`)}
                className="cmpImg" key={company.companyName}
                onClick={(e) => onImageClick(company)}
            />
            <p className='logo_text'>{company.name}</p></div></a>
        )
    })
    const [org, setOrg] = useState(``);
    const [inputValue, setInputValue] = useState(``)

    const { instance } = useMsal();
    const location = useLocation();
    useEffect(() => {
        const msalkey = sessionStorage.getItem('msal.token.keys.9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d')
        if (!msalkey) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
        }
    }, [location, orgs, dispatch])


    return (
        <>
            <Loader />
            <div className="tcsLogo" style={{ marginTop: '60px' }}>
                <Row>
                    <Col>
                        <div >
                            <img src={tcsLogo} alt={`no image`} />
                        </div>
                        {/* <div className='core_logo'> */}
                        {/* <img src={kyc_logo_white} alt={`no tagLine`} className="logoTagline" /> */}
                        <Box
                            component="img"
                            sx={{
                                width: '493px',
                                height: '156px'
                            }}
                            alt="The house from the offer."
                            src={kyc_logo_white} />
                        <Typography className='logo_caption' sx={{ fontFamily: 'OpenSans' }}>A One-Stop Solution for Customer’s Organizational Data</Typography>
                        {/* </div> */}
                        <div>
                            <SearchComplete placeHolder={kycConstants.searchOrgPlcHolder}
                            />
                        </div>
                        <MobileNotification />
                    </Col>
                </Row>
                {/* <Row className="recommendations">
                    <Col>
                        <div >
                            <Row>
                                <Col className='recom_text'>
                                    <span> Our Recommendations</span>
                                </Col>
                            </Row>
                        </div>

                        <div className="imgList">
                            {DetList}
                        </div>
                    </Col>
                </Row> */}
            </div>
        </>
    )
}
export default Home;