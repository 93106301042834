const initialState = {
    BenchmarkName: ``
}
const BenchmarkDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SEARCH': {
            return Object.assign({}, state, {
                finSearch: action.value
            });
        }
        case 'SET_SEARCH': {
            return Object.assign({}, state, {
                finSearch: action.data
            });
        }
        case 'DEF_FIN': {
            return Object.assign({}, state, {
                selFin: action.data
            });
        }

        case 'GET_FINANCIAL_PERFOMANCE': {
            return Object.assign({}, state, {
                benchmarkFinancialData: action.value
            });
        }
        case 'SET_FINANCIAL_PERFOMANCE': {
            return Object.assign({}, state, {
                benchmarkFinancialData: action.data
            });
        }
        case 'GET_COMPETITORS': {
            return Object.assign({}, state, {
                benchmarkCompetitorsData: action.value
            });
        }
        case 'SET_COMPETITORS': {
            return Object.assign({}, state, {
                benchmarkCompetitorsData: action.data
            });
        }
        case 'GET_CUSTOMER_SATISFACTION': {
            return Object.assign({}, state, {
                benchmarkCustSatData: action.value
            });
        }
        case 'SET_CUSTOMER_SATISFACTION': {
            return Object.assign({}, state, {
                benchmarkCustSatData: action.data
            });
        }
        case 'GET_DIGITAL_PERFORMANCE': {
            return Object.assign({}, state, {
                benchmarkDigPerfData: action.value
            });
        }
        case 'SET_DIGITAL_PERFORMANCE': {
            return Object.assign({}, state, {
                benchmarkDigPerfData: action.data
            });
        }
        default: {
            return Object.assign({}, state);
        }
    }
}
export default BenchmarkDetails;