
import React, { useState } from "react";
import "../key Executives/keyExecutive.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";



const KeyExecutiveTable = (props) => {
  // console.log('exe data',props?.data?.data)
  const keyData = props?.data?.data;
  const headers = ["Name", "Title"];
  const [showAllRows, setShowAllRows] = useState(false);
  const visibleRows = showAllRows ? keyData : keyData?.slice(0, 5);
  const buttonText = showAllRows ? "- View Less" : "+ View More";

  const toggleRowsVisibility = () => {
    setShowAllRows(!showAllRows);
  };

  return (
    <div style={styles.container}>
      <TableContainer component={Paper} sx={{ boxShadow: 'none', overflow: 'hidden' }} >
        <Typography variant="h6" component="h6" style={styles.title} >
          Key Executives
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell className="table-cell" key={index} style={styles.headers}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={styles.tableBody}>
            {visibleRows?.map((row, index) => (
              <TableRow className="table-row" key={index}>
                <TableCell className="table-cell" style={styles.Table}>{row.name}</TableCell>
                <TableCell className="table-cell" style={styles.Table}>{row.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {keyData?.length > 5 && (
          <Typography
            variant="subtitle1"
            component="span"
            style={styles.viewMoreLess}
            onClick={toggleRowsVisibility}
          >
            {buttonText}
          </Typography>
        )}
      </TableContainer>
    </div>
  );
};
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    fontFamily: 'OpenSans'
  },
  Table: {
    fontFamily: 'OpenSans'
  }
  ,
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    padding: '10px',
    fontFamily: 'OpenSans'
  },
  tableBody: {
    backgroundColor: 'white',
    padding: '20px',
  },
  headers: {
    fontWeight: 'bold',
    fontFamily: 'OpenSans'
  },
  viewMoreLess: {
    cursor: 'pointer',
    color: '#2924A6',
    // textDecoration: 'underline',
    // fontWeight: 'bold',
    marginTop: '10px',
    padding: '5px',
    fontSize: '14px',
    fontFamily: 'OpenSans'
  }
};
export default KeyExecutiveTable;
