import React from 'react'
import { Container, Typography, Grid, Link } from '@mui/material'
import Carousel from 'react-material-ui-carousel';
import Paper from '@mui/material/Paper';
import no_data_img from '../../../assets/imgs/Landing_Page/no_data_img.png'
import resultsNotFound from '../../../assets/imgs/errorHandlingPngs/resultsNotFound.png'

const CriticalHighlights = (params) => {
  const reviewsData = params?.CriticalParams?.negative_highlights;

  if (reviewsData === '-') {
    return (
      <Container style={Styles.container} sx={{ height: '100%' }}>
        <Typography style={Styles.title}>Critical Comments</Typography>
        <Carousel animation='slide'>
          <div>
            <img className='logo' src={resultsNotFound} alt={`no tagLine`} class="center" style={{ height: '30%', width: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
            {/* <Typography style={Styles.errorHandle}>No data available</Typography> */}
          </div>
        </Carousel>
      </Container>
    );
  }

  const items = reviewsData?.map((item) => (
    <Paper key={item} style={Styles.paper}>
      <div>
        <Typography style={Styles.content}>{item?.review_content}</Typography>
      </div>
      <div style={Styles.userDetails}>
        <Grid container spacing={1} alignItems='left'>
          <Grid item>
            <Typography variant='body' style={Styles.border}>{item?.review_date}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body' style={Styles.border}>{item?.review_author}</Typography>
          </Grid>
          <Grid item >
            <Typography variant='body' style={Styles.lastBorder}>
              <Link href={item?.source} underline="hover" target="_blank" rel="noopener noreferrer">
                {item?.site}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  ));

  return (
    <Container style={Styles.container}>
      <Typography style={Styles.title}>Critical Highlights</Typography>
      <Carousel autoPlay={false} >
        {items}
      </Carousel>
    </Container>
  );
}

const Styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'justify',
    fontFamily: 'OpenSans',
  },
  paper: {
    height: 'auto',
    backgroundColor: 'white',
    paddingBottom: '20px'
  },
  content: {
    display: 'inline',
    marginRight: '8px',
    fontFamily: 'OpenSans'
  },
  link: {
    cursor: 'pointer',
    color: 'blue',
    // textDecoration: 'underline',
    fontFamily: 'OpenSans'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    fontFamily: 'OpenSans'

  },
  border: {
    borderRight: '1.5px solid black',
    padding: '0 10px',
    color: '#5E5A5A',

  },
  lastBorder: {
    borderRight: 'none'
  },
  userDetails: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  errorHandle: {
    textAlign: 'center',
    marginTop: '15px'
  }
}

export default CriticalHighlights;




