import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import AppPop from './AppPopUp';
const MobileNotification = () => {
    const [isMobile, setIsMobile] = useState(false);
    const Notification = () => {
        return (
            <div>
                <AppPop />
            </div>
        )
    }

    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        setIsMobile(result.device.type === 'mobile');
    }, []);

    return (
        <div>

            {isMobile && <Notification />}

            {/* Render the rest of your app */}
            {/* ... */}
        </div>
    );
};

export default MobileNotification;
