import { Typography, Collapse, Box } from '@mui/material';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
const Description = ({ description, maxCharacters = 500 }) => {

  const [expanded, setExpanded] = useState(false);
  const [displayedDescription, setDisplayedDescription] = useState('');

  if (description === undefined) {
    return (
      <div style={styles.container}>
        <div style={{ alignItems: 'center' }}>
          <Typography variant="body1" component="p" style={styles.description} >
            <Typography style={styles.title}>Description</Typography>
            <Skeleton width={300} />
            <Box sx={{ width: 720, alignItems: 'center' }}>
              <Skeleton />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          </Typography>
        </div>
      </div>

    )
  }

  const shouldTruncate = description?.length > maxCharacters;
  const truncatedDescription = shouldTruncate
    ? `${description.substring(0, maxCharacters)}...`
    : description;

  const handleExpandClick = () => {
    setExpanded(!expanded);

    if (!displayedDescription) {
      setDisplayedDescription(description);
    } else if (!expanded) {
      setDisplayedDescription(description);
    } else {
      setDisplayedDescription('');
    }
  };

  const renderedDescription = expanded ? description : displayedDescription || truncatedDescription;
  return (
    <div style={styles.container}>
      <Typography variant="body1" component="p" style={styles.description} >
        <Typography style={styles.title}>Description</Typography>
        {renderedDescription}
        {shouldTruncate && (
          <Typography
            variant="body2"
            component="span"
            style={styles.expand}
            onClick={handleExpandClick}
          >
            {expanded ? ' Read less' : ' Read more'}
          </Typography>
        )}
      </Typography>
    </div>
  );

};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  description: {
    fontSize: '16px',
    lineHeight: '1.8',
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    textAlign: 'justify',
    padding: '20px',
    fontFamily: 'OpenSans'
  },
  expand: {
    cursor: 'pointer',
    // textDecoration: 'underline',
    color: '#2924A6',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    fontFamily: 'OpenSans'
  },
};
export default Description;
