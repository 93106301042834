import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
// import InputAdornment from '@mui/material/InputAdornment';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/SearchRounded';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgSearchInfo, setDefOrg } from '../../screens/Oranizational_Overview/orgAction';
import './Search.css';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export const SearchComplete = (props) => {
    // console.log("search cmpoennet call+++++")
    const currentPath = window.location.pathname
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orgs = useSelector((state) => state.AllOrgs.orgsSearch);
    const selOrg = useSelector((state) => state.AllOrgs.selOrg);
    // console.log(selOrg, `searchprops for selected value`);
    const [org, setOrg] = useState(``);
    const [inputValue, setInputValue] = useState(``)
    const [onLoad, setOnLoad] = useState(false)
    const [companyCode, setCompanyCode] = useState('')
    const [color, setColor] = useState('')
    // console.log("onloadd valueeee" , onLoad)
    const MySearch = () => {
        // debugger;
        //    console.log(org, inputValue, `input`)
        let val = inputValue ? inputValue : selOrg
        // console.log(val?.length, 'search')
        let storedOrg = sessionStorage.getItem(`org`);
        sessionStorage.setItem(`org`, val ? val : storedOrg);
        dispatch(setDefOrg(val ? val : storedOrg));

        if (currentPath !== "/") {
            navigate(currentPath);
        } else if (val?.length >= 5) {
            navigate('/org');
        }
        if (val?.length === undefined) {
            toastr.options.positionClass = 'toast-bottom-center';
            toastr.options.closeButton = true;
            toastr.info('please select company')
        } else if (val === 'null') {
            toastr.options.positionClass = 'toast-bottom-center';
            toastr.info('please select company')
        }
    }
    useEffect(() => {
        window.location.pathname === '/benchmark' ? setColor('#E2E5F4') : setColor('white')
    }, [])

    const searchBarSx = {
        bgcolor: color,
        fontFamily: 'OpenSans'
    }

    useEffect(() => {
        if (onLoad === false && !orgs) {
            setOnLoad(true)
            dispatch(getOrgSearchInfo());
        }
    }, [onLoad, orgs])

    useEffect(() => {
        if (org) {
            let val = sessionStorage.getItem(`org`);
            sessionStorage.setItem(`org`, org ? org : val);
            dispatch(setDefOrg(org ? org : val));
            if (currentPath !== "/") {
                navigate(currentPath);
            } else {
                navigate('/org');
                // alert('Please enter something to search')
            }
        }
    }, [org])





    return (
        <div className='search'>
            <Autocomplete
                value={selOrg ? selOrg : sessionStorage.getItem(`org`)}
                defaultValue={org ? org : props.org}
                onChange={(event, newValue) => { setOrg(newValue) }}
                inputValue={inputValue}
                onInputChange={(event, newvalue) => setInputValue(newvalue)}
                getOptionLabel={(option) => option}
                id="kycSearch"
                key={(option) => option.company_code}
                disableClearable
                clearOnEscape
                options={(orgs && orgs.length > 0) ? orgs.map((option) => option.company_name) : []}
                noOptionsText={"No Matches found"}
                multiple={false}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        paddingRight: '0% !important',
                        backgroundColor: 'white',
                        // bgcolor: { color },
                        borderColor: 'white',
                        fontFamily: 'OpenSans'
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        placeholder={props.placeHolder}
                        //onChange={(e) => setOrg(e.target.value)}
                        sx={{
                            m: 1, flex: 1, width: "50%", borderRadius: "40ch",
                            '& .MuiOutlinedInput-root, & .MuiAutocomplete-inputRoot ': {
                                paddingRight: '0% !important', borderColor: 'white'
                            },
                        }}
                        {...params}
                        value={props?.defaultvalue}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            endAdornment: <InputAdornment position="end">
                                <Button
                                    sx={{ fontFamily: 'OpenSans' }}
                                    className="exploreButton"
                                    onClick={() => MySearch()}>Explore
                                </Button>

                            </InputAdornment>,
                        }}

                    />
                )}
            />

        </div >
    )
}
export default SearchComplete;