
import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { Margin } from '@mui/icons-material';
import { ThreeCircles } from "react-loader-spinner";
import Box from '@mui/material/Box';
import { getCurrencySymbol } from '../../../utils/utils';
import resultsNotFound from '../../../assets/imgs/errorHandlingPngs/resultsNotFound.png'
const PeerTable = (props) => {
  const tableHeaders = ['Organization Name', 'Revenue', 'COGS %', 'SG&A %', 'Operating Margin', 'Net Opertaing Cash']
  const tableData = props?.finPeerTable?.data
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  if (tableData?.length === 0) {
    return (
      <div style={Styles.container}>
        <Typography variant="h6" component="h4" style={Styles.title}>
          Peer Valuation Analysis
        </Typography>
        <img src={resultsNotFound} alt='No Data Available' style={{ height: '60%', width: '60%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
      </div>
    )
  }
  return (
    <div style={Styles.container}>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Typography variant="h6" component="h4" style={Styles.title}>
          Peer Valuation Analysis
        </Typography>
        <Typography variant="h6" component="h4" style={Styles.caption}>TTM</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
          {loading && <ThreeCircles
            height="50"
            width="50"
            color="#2924A6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />}
        </Box>
        {!loading &&
          <Table sx={{ minWidth: 650 }} aria-label="key competitors table">
            <TableHead>
              <TableRow sx={{ backgroundColor: 'white' }}>
                {tableHeaders.map((header) => (
                  <TableCell key={header} sx={{ fontWeight: 'bold', color: 'black', padding: '5px', textAlign: header === tableHeaders[0] ? 'left' : 'right', fontFamily: 'OpenSans' }} >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={Styles.table}>
              {tableData?.map((data, index) => (
                <TableRow key={index} style={Styles.underline}>
                  <TableCell style={Styles.table} sx={{ height: '10px', padding: '5px', fontFamily: 'OpenSans', width: '160px', }} align="left">
                    {data?.company_name}<br />
                    {/* ({data?.revenue_year}) */}
                  </TableCell>
                  <TableCell align="right" sx={{ height: '10px', width: '100px', fontFamily: 'OpenSans', padding: '5px' }}>
                    {getCurrencySymbol(data?.company_currency)}{data?.revenue}
                  </TableCell>
                  <TableCell align="right" sx={{ height: '10px', width: '80px', fontFamily: 'OpenSans', padding: '0px' }}>
                    {data?.cogs}
                  </TableCell>
                  <TableCell align="right" sx={{ height: '10px', width: '80px', fontFamily: 'OpenSans', padding: '6px' }}>
                    {data?.sga}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '5px', width: '85px' }}>
                    {data?.operating_margin}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: '3px', width: '85px' }}>
                    {getCurrencySymbol(data?.company_currency)}{data?.net_operating_cash}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
      <Typography sx={{ fontSize: '14px', color: '#888888', fontFamily: 'OpenSans' }}>*The financial year of each organization may vary as per the latest published values</Typography>
    </div>
  );
};
const Styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    fontFamily: 'OpenSans'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    padding: '10px',
    paddingBottom: '0px',
    fontFamily: 'OpenSans'
  },
  underline: {
    borderBottom: '1px solid #ccc', // add an underline to the rows
  },
  table: {
    height: '10px',
    fontFamily: 'OpenSans'
  },
  caption: {
    marginLeft: '10px',
    fontSize: '14px',
    color: 'black'
  }
}
export default PeerTable;



