

import React from "react";
import { Typography, Container, Grid, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import no_image_circular from '../../../assets/imgs/Landing_Page/no_image_circular.png'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import imageNotFound from '../../../assets/imgs/errorHandlingPngs/imageNotFound.png'


const SummaryofFeedback = (params) => {
  // console.log(params?.wordCloudparams, 'word')
  if (params?.wordCloudparams === undefined) {
    return (
      <Container maxWidth="md" style={Styles.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={Styles.header}>
              <Typography style={Styles.title}>Summary of Feedback</Typography>
            </div>
            <img className='logo' src={imageNotFound} alt={`no tagLine`} class="center" style={{ height: '70%', width: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

            {/* <Typography sx={{ textAlign: 'center', paddingBottom: '20px' }}>No Image Available</Typography> */}

          </Grid>
        </Grid>
      </Container>
    )
  }
  return (
    <Container maxWidth="md" style={Styles.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={Styles.header}>
            <Typography style={Styles.title}>Summary of Feedback</Typography>
            <a style={{ textDecoration: 'none' }} href={params?.wordCloudparams} download={`${params?.companyCode}_feedback`} >
              <Button style={Styles.deleteIcon}
                variant="contained"
                // color="#2924A6"
                startIcon={<FileDownloadOutlinedIcon />}
              >
                Download
              </Button>
            </a>
          </div>
          <Grid item xs={12}>
            <img src={params?.wordCloudparams} alt="Loading" style={Styles.image} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
const Styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: '10px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "auto",
    marginTop: '10px'
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  downloadButton: {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: 'white',
    variant: 'text',
    size: 'small'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    padding: '10px',
    textAlign: 'left'
  },
  deleteIcon: {
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: '#F5F6FB',
    minWidth: '10px',
    color: '#2924A6',
    boxShadow: 'none',
    fontWeight: 700
  }
};
export default SummaryofFeedback;
