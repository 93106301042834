export const DEF_FIN = `DEF_FIN`;
export const SET_ALL_RESOURCES = 'SET_ALL_RESOURCES';
export const GET_ALL_RESOURCES = 'GET_ALL_RESOURCES';

export const setDefFin = (data) => ({
    type: DEF_FIN,
    data
})
export const getallResourcesInfo = (params) => ({ type: GET_ALL_RESOURCES, companyCode: params });

export const setallResourcesInfo = (data) => ({
    type: SET_ALL_RESOURCES,
    data
})