import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import Card from 'react-bootstrap/Card';
import 'react-circular-progressbar/dist/styles.css';
import './circularProgresscomponent.css'

const CircularProgressComponent = (props) => {
    return (
        <Card className='progress'>
            <h3>Web Scraping %</h3>
            <CircularProgressbar
                value={props.progress}
                text={`${props.progress}%`}
                className='probar'
            />
        </Card>
    );
}

export default CircularProgressComponent;