const initialState = {
    FinName: ``
}
const FinDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SEARCH': {
            return Object.assign({}, state, {
                finSearch: action.value
            });
        }
        case 'SET_SEARCH': {
            return Object.assign({}, state, {
                finSearch: action.data
            });
        }
        case 'DEF_FIN': {
            return Object.assign({}, state, {
                selFin: action.data
            });
        }

        case 'GET_INCOME_STATEMENT_YEARLY': {
            return Object.assign({}, state, {
                finIncomeYearly: action.value
            });
        }
        case 'SET_INCOME_STATEMENT_YEARLY': {
            return Object.assign({}, state, {
                finIncomeYearly: action.data
            });
        }

        case 'GET_BAL_SHEET_QTRLY': {
            return Object.assign({}, state, {
                finBalSheetQly: action.value
            });
        }
        case 'SET_BAL_SHEET_QTRLY': {
            return Object.assign({}, state, {
                finBalSheetQly: action.data
            });
        }
        case 'GET_CASH_FLOW_QTRLY': {
            return Object.assign({}, state, {
                finCashFlowQly: action.value
            });
        }
        case 'SET_CASH_FLOW_QTRLY': {
            return Object.assign({}, state, {
                finCashFlowQly: action.data
            });
        }
        case 'GET_KEY_RATIO_YEARLY': {
            return Object.assign({}, state, {
                finKeyRatioYly: action.value
            });
        }
        case 'SET_KEY_RATIO_YEARLY': {
            return Object.assign({}, state, {
                finKeyRatioYly: action.data
            });
        }
        case 'GET_BALANCE_SHEET_YEARLY': {
            return Object.assign({}, state, {
                finBalancesheetYearly: action.value
            });
        }
        case 'SET_BALANCE_SHEET_YEARLY': {
            return Object.assign({}, state, {
                finBalancesheetYearly: action.data
            });
        }
        case 'GET_CASH_FLOW_YEARLY': {
            return Object.assign({}, state, {
                finCashflowYearly: action.value
            });
        }

        case 'SET_CASH_FLOW_YEARLY': {
            return Object.assign({}, state, {
                finCashflowYearly: action.data
            });
        }

        case 'GET_INCOME_STATEMENT_QUARTERLY': {
            return Object.assign({}, state, {
                finIncomestatementQuarterly: action.value
            });
        }

        case 'SET_INCOME_STATEMENT_QUARTERLY': {
            return Object.assign({}, state, {
                finIncomestatementQuarterly: action.data
            });
        }

        case 'GET_AVERAGE_GROWTH': {
            return Object.assign({}, state, {
                finAverageGrowth: action.value
            });
        }

        case 'SET_AVERAGE_GROWTH': {
            return Object.assign({}, state, {
                finAverageGrowth: action.data
            });
        }


        case 'GET_REVENUE_NETPROFIT': {
            return Object.assign({}, state, {
                finRevenueNetprofit: action.value
            });
        }

        case 'SET_REVENUE_NETPROFIT': {
            return Object.assign({}, state, {
                finRevenueNetprofit: action.data
            });
        }

        case 'GET_PEER_TABLE': {
            return Object.assign({}, state, {
                finPeerTable: action.value
            });
        }

        case 'SET_PEER_TABLE': {
            return Object.assign({}, state, {
                finPeerTable: action.data
            });
        }


        default: {
            return Object.assign({}, state);
        }
    }
}
export default FinDetails;