import React, { useEffect, useState } from "react";
import './financial.css';
import { Link, NavLink, Routes, Route } from 'react-router-dom';
import Incomestatement from "../../container/finIncomeTab/finIncomeTab";
import KeyRat from "../../container/finKeyTab/finKeyTab";
import BalanceSheet from '../../container/finBalanceTab/finBalanceTab';
import CashFlow from '../../container/finCashFlowTab/finCashFlowTab';
import Summary from "./summary";
import { Typography } from '@mui/material';

function Financial(props) {
    // console.log(window.location.pathname, 'path')
    return (
        <div>
            <Typography style={Styles.title}>Financial Overview</Typography>
            {/* <Typography sx={{ fontSize: '12px', marginLeft: '10px', fontFamily: 'OpenSans', color: '#888888' }}>Data Sources: Yahoo Finance</Typography> */}
            <div className="fin">
                <NavLink className='summary' to="/fin/summary" id="link" >Summary</NavLink>
                <NavLink className='income' to="/fin/income" id="link">Income Statement</NavLink>
                <NavLink className='balance' to="/fin/balance" id="link">Balance Sheet</NavLink>
                <NavLink className='cashflow' to="/fin/cashflow" id="link">Cash Flow</NavLink>
                <NavLink className='keyratio' to="/fin/keyratio" id="link">Key Ratios</NavLink>
            </div>
            <div>
                <Routes>
                    <Route path="/summary" element={<Summary AvgData={props.AverageGrowth} RevData={props.RevenueNetprofit} finPeerTable={props.finPeerTable} currency={props.currencyData} />} />
                    <Route path="/income" element={<Incomestatement data={props.incomeStatement}
                        qtrlyData={props.IncomeStatementQuarterly} currency={props.currencyData} />} />
                    <Route path="/balance" element={<BalanceSheet data={props.BalanceSheetYearly}
                        qtrlyData={props.balanceSheetQtrly} currency={props.currencyData} />} />
                    <Route path="/cashflow" element={<CashFlow data={props.CashFlowYearly}
                        qtrlyData={props.cashFlowQtrly} currency={props.currencyData} />} />
                    <Route path="/keyratio" element={<KeyRat data={props.keyRatio} currency={props.currencyData} />} />
                </Routes>

            </div>
        </div>

    )
}
const Styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '20px'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'Black',
        padding: '10px',
        paddingBottom: '0px'
    },
}
export default Financial;