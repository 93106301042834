import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

const AppPop = () => {
    const [showPopUp, setShowPopUp] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopUp(true);
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (showPopUp) {
            toast.info('Please use the desktop version of the website for the best experience.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
            });
        }
    }, [showPopUp]);

    return (
        <div>
            <ToastContainer />
        </div>
    );
};

export default AppPop;
