import React, { useState, useEffect } from "react";
import './financialgrow.css';
import { Skeleton } from '@mui/material';

const FinancialGrowbox = (props) => {
  const [item, setItem] = useState(true)
  useEffect(() => {
    if (props.data === undefined) {
      setItem(false)
    }
    else {
      setItem(true)
    }
  }, [props.data])
  const SkeletonTheme = () => {
    return (
      <Skeleton width={60} />
    )

  }
  return (
    <div className='fin-flex-main-container'>
      <div className='fin-flex-titles'>
        <h3>Compound Average Growth Rate (in %)</h3>
        <h8 className='year-data'>{props.data?.data[0].EndYear} - {props.data?.data[0].StartYear}</h8>
      </div>
      <div class="fin-flex-container">
        <div class="card"><p>Revenue</p><h3>{item ? props.data?.data[0].Revenue : <SkeletonTheme />}</h3></div>
        <div class="card"><p>Net Income</p><h3>{item ? props.data?.data[0].NetIncome : <SkeletonTheme />}</h3></div>
        <div class="card"><p>Earnings Per Share</p><h3>{item ? props.data?.data[0].EarningPerShare : <SkeletonTheme />}</h3></div>
        <div class="card"> <p>Capital Spending</p><h3>{item ? props.data?.data[0].CapitalSpending : <SkeletonTheme />}</h3></div>
        <div class="card"><p>Gross Margin</p><h3>{item ? props.data?.data[0].GrossMargin : <SkeletonTheme />}</h3></div>
        <div class="card"><p>Cash Flow</p><h3>{item ? props.data?.data[0].CashFlow : <SkeletonTheme />}</h3></div>
      </div>
    </div>
  );
};

export default FinancialGrowbox;