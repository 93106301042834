import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import '../lineChart/linechart.css'

const labels = ["FY20", "FY21", "FY22", "FY23"];

const data = {
  labels: labels,
  datasets: [
    {
      label: "Debt/Equity Ratio",
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgb(255, 99, 132)",
      data: [0, 10, 5, 12],
      pointRadius:5,
    //   responsive:true,
    },
    
    {
        label: "EBIDDA",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(125, 129, 19)",
        data: [6,15, 12, 25],
        // responsive:true,
      },
      {
        label: "EBID",
        backgroundColor: "rgb(250, 99, 132)",
        borderColor: "rgb(125, 129, 19)",
        data: [4,12, 10, 20],
        // responsive:true,
      },
  ],
};

const LineChart = (props) => {
      return (
      <div className="chart">
        <Line data={props.data?props.data:{labels:[],datasets:[]}} />
      </div>
    );
  };


export default LineChart;