import React from "react";
import { Pie } from "react-chartjs-2";




const PieChart = (props) => {
  const data = {
    labels: [
      'Female',
      'Male'
    ],
    datasets: [{
      // label: 'My First Dataset',
      data: [props?.data?.Female, props?.data?.Male],
      backgroundColor: [
        '#FFD9DD',
        '#8EFFFA'
      ],
      hoverOffset: 4
    }]
  };
  // console.log('pie chart val',params.data.CustomerDemography.data[0].Female)
  return (
    <div>
      <Pie data={data} width='120px' height='120px' />
    </div>
  );
};


export default PieChart;