export const SET_CUST_SAT_PARAMS ='SET_CUST_SAT_PARAMS';
export const GET_CUST_SAT_PARAMS ='GET_CUST_SAT_PARAMS';
export const SET_GENDER_PARAMS ='SET_GENDER_PARAMS';
export const GET_GENDER_PARAMS ='GET_GENDER_PARAMS';
export const SET_AGE_PARAMS ='SET_AGE_PARAMS';
export const GET_AGE_PARAMS ='GET_AGE_PARAMS';
export const SET_POS_PARAMS ='SET_POS_PARAMS';
export const GET_POS_PARAMS ='GET_POS_PARAMS';
export const SET_CRITICAL_PARAMS ='SET_CRITICAL_PARAMS';
export const GET_CRITICAL_PARAMS ='GET_CRITICAL_PARAMS';
export const SET_WEB_SCRAP_PARAMS ='SET_WEB_SCRAP_PARAMS';
export const GET_WEB_SCRAP_PARAMS ='GET_WEB_SCRAP_PARAMS';
export const SET_WORD_CLOUD_PARAMS ='SET_WEB_WORD_CLOUD_PARAMS';
export const GET_WORD_CLOUD_PARAMS ='GET_WEB_WORD_CLOUD_PARAMS';

export const getCustSatParams =(params)=>({type:GET_CUST_SAT_PARAMS,companyCode:params});

export const setCustSatParams=(data)=>({
    type:SET_CUST_SAT_PARAMS,
    data
})

export const getGenderParams =(params)=>({type:GET_GENDER_PARAMS,companyCode:params});

export const setGenderParams=(data)=>({
    type:SET_GENDER_PARAMS,
    data
})

export const getAgeParams =(params)=>({type:GET_AGE_PARAMS,companyCode:params});

export const setAgeParams=(data)=>({
    type:SET_AGE_PARAMS,
    data
})

export const getPosParams =(params)=>({type:GET_POS_PARAMS,companyCode:params});

export const setPosParams=(data)=>({
    type:SET_POS_PARAMS,
    data
})

export const getCriticalParams =(params)=>({type:GET_CRITICAL_PARAMS,companyCode:params});

export const setCriticalParams=(data)=>({
    type:SET_CRITICAL_PARAMS,
    data
})

export const getWebScrapParams =(params)=>({type:GET_WEB_SCRAP_PARAMS,companyCode:params});

export const setWebScrapParams=(data)=>({
    type:SET_WEB_SCRAP_PARAMS,
    data
})

export const getWordCloudParams =(params)=>({type:GET_WORD_CLOUD_PARAMS,companyCode:params});

export const setWordCloudParams=(data)=>({
    type:SET_WORD_CLOUD_PARAMS,
    data
})
