import React from "react";
import MixedChart from "../../container/charts/mixedChart/MixedChart";
import FinancialGrowbox from '../../container/Flexboxes/financialgrow';
import PeerTable from '../../container/Tables/PeerTbl';
// import ScrollingText from "../../container/scroll text/ScrollingText";
import './summary.css';



function Summary(props) {
    // console.log("avg data in summ",props.AvgData)
    // console.log("revenue netprof",props.RevData)

    return (

        <div>
            <div>

                <div className="averagegropw">
                    <FinancialGrowbox data={props.AvgData} />
                </div>
                {/* <div className="mixed-chart">
                    <h4 className="h4">Revenue & Net Profit Trend</h4>
                        <MixedChart data={props.RevData}/>
                    </div> */}
                <div style={{ height: '500px' }}>
                    <MixedChart data={props.RevData} />
                </div>
                <div className="peertable">
                    <PeerTable finPeerTable={props.finPeerTable} currency={props.currency} />
                </div>

            </div>
        </div>
    )
}
export default Summary;


