

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import startScreen from '../../../assets/imgs/startScreen/startScreen.png'
import SignInButton from './LoginButton';
import kyc_startScreen from '../../../assets/imgs/startScreen/kyc_startScreen.png'

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={defaultTheme}>
            {/* <div style={{ backgroundColor: '#F5F6FB' }}> */}
            <Grid container component="main" sx={{ height: '100vh', backgroundColor: '#F5F6FB' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${startScreen})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ boxShadow: 'none' }}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'none'
                        }}
                    >

                        <div>
                            <img src={kyc_startScreen} alt="Logo" style={styles.logo} />
                            <Box >
                                <Typography style={styles.welcomeNote}>Welcome to</Typography>
                                <Typography style={styles.welcomeNote}>Know Your Customer</Typography>
                            </Box>
                            <div style={styles.button}>
                                <SignInButton />
                            </div>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

const styles = {
    welcomeNote: {
        fontFamily: 'openSans',
        fontWeight: 700,
        color: '#5E5A5A',
        fontSize: '26px',
        textAlign: 'center'
    },
    button: {
        marginTop: '20px',
        marginLeft: '130px'
    },
    logo: {
        height: '50%',
        width: '50%',
        marginLeft: '80px',
    }
}