import React, { useState, useEffect, useRef } from "react";
import DataTable from "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from 'jquery';
import AddResponse from "./AddResource";
import Table from '@mui/material/Table';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';


const ResourceTable = (props) => {
    const [tableData, setTableData] = useState([]);
    const [isDeleteDialogueOpen, setIsDeleteDialogueOpen] = useState(false)
    const [delCount, setDelCount] = useState([])

    useEffect(() => {
        const dataArr = props?.data
        setTableData(dataArr)
    }, [props?.data])

    const [table, setTable] = useState(null);

    useEffect(() => {
        if (!tableData) return;

        const newTable = $("#example").DataTable({
            data: tableData,
            //     columns: [
            //         {
            //             title: '<input type="checkbox" id="select-all"/>',
            //             data: null,
            //             orderable: false,
            //             className: 'select-checkbox',
            //             defaultContent: '<input type="checkbox" />'
            //         },
            //         {
            //             title: "Name",
            //             data: "owner_name",
            //             render: function (data, type, row, meta) {
            //                 return '<a href="' + row.resource_link + '" target="_blank" style="text-decoration: none; color: #2924A6;" rel="noopener noreferrer">' + data + '</a>';
            //             }
            //         },
            //         { title: "Document Type", data: "type" },
            //         { title: "Date", data: "date" },
            //         { title: "Size", data: "size" },
            //         // { title: "Type", data: "type" },
            //     ],
            //     select: {
            //         style: "multi",
            //         selector: "td:first-child"
            //     },
            // });
            columns: [
                {
                    title: '<input type="checkbox" id="select-all"/>',
                    data: null,
                    orderable: false,
                    className: 'select-checkbox',
                    defaultContent: '<input type="checkbox" />'
                },
                {
                    title: "Title",
                    data: "title",
                    render: function (data, type, row, meta) {
                        return '<a href="' + row.resource_link + '" target="_blank" style="text-decoration: none; color: #2924A6;" rel="noopener noreferrer">' + data + '</a>';
                    }
                },
                { title: "Report Type", data: "owner_name" },
                { title: "Date", data: "date" },
                { title: "Size", data: "size" },
                { title: "File Type", data: "type" },
            ],
            select: {
                style: "multi",
                selector: "td:first-child"
            },
        });
        const updateSelectAllCheckbox = () => {
            const selectedRows = newTable.rows({ selected: true }).count();
            const allRows = newTable.rows().count();
            $("#select-all").prop("checked", selectedRows === allRows);
        };

        const attachEventHandlers = (table, tableData) => {
            table.on("select deselect", updateSelectAllCheckbox);

            $('#select-all').on('click', function () {
                var rows = table.rows({ 'search': 'applied' }).nodes();
                $('input[type="checkbox"]', rows).prop('checked', this.checked);
                $(rows).toggleClass('selected', this.checked);
            });

            $('#example tbody').on('click', 'input[type="checkbox"]', function () {
                $(this).closest('tr').toggleClass('selected');
                var selectedRows = table.rows('.selected').nodes().length;
                var totalRows = table.rows().nodes().length;
                $('#select-all').prop('checked', selectedRows === totalRows);
            });

            $("#delete").off().on("click", function () {
                const selectedRows = table.rows(".selected").data();
                if (selectedRows.length === 0) {
                    toastr.options.positionClass = 'toast-top-center';
                    toastr.options.closeButton = true;
                    toastr.warning("Please select at least one row to delete.");
                    return;
                }
                let delVal = []
                const selectedIds = selectedRows.map((row) => { delVal.push(row.resource_id) })
                console.log("Deleting rows with ids: ", delVal);
                deleteRows(delVal);

                // Update the table data and reattach event handlers
                table.clear();
                table.rows.add(tableData);
                attachEventHandlers(table, tableData);
                $("#example tbody input[type='checkbox']").prop("checked", false);
                $("#select-all").prop("checked", false);
            });
        };

        attachEventHandlers(newTable, tableData);
        // Add a click event listener to the refresh button
        $("#refresh-btn").on("click", function () {
            // Reload the current page to refresh the table
            window.location.reload();
        });

        setTable(newTable);

        return () => {
            newTable.destroy();
        };
    }, [tableData]);

    if (!tableData) {
        return <div><center>Loading...</center></div>;
    }



    const handleDeleteClick = () => {
        setIsDeleteDialogueOpen(true);
    }

    const handleDeleteCancel = () => {
        setIsDeleteDialogueOpen(false);
    }
    const handleClose = () => {
        toastr.options.positionClass = 'toast-top-center';
        toastr.options.closeButton = true;
        toastr.options.progressBar = true;
        toastr.info("Deleting the files");
        setTimeout(function () {
            toastr.clear();
        }, 1000)
        setIsDeleteDialogueOpen(false)
    }

    const deleteRows = async (ids) => {
        try {
            const requestBody = {
                file_ids: ids
            };
            const response = await fetch("https://testcoe1.azurewebsites.net/api/resources/deletefiles", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
            });
            if (!response.ok) {
                throw new Error("Failed to delete rows");
            }
            const newTableData = tableData.filter(row => !ids.includes(row.resource_id));
            setTableData(newTableData);
            $("#example").DataTable().rows(".selected").remove().draw(false);
            const jsonResponse = await response.json();
            // console.log(jsonResponse, 'rrrr');
        } catch (error) {
            console.error(error);
        }
    };

    const downloadSelected = async () => {
        const table = $("#example").DataTable();
        const selectedRows = table.rows(".selected").data();
        let selectedArr = []
        const selectedIds = selectedRows.map(row => selectedArr.push(row.resource_id));
        console.log("Downloading rows with ids: ", selectedArr);
        if (selectedArr.length <= 0) {
            toastr.clear()
            toastr.options.positionClass = 'toast-top-center';
            toastr.options.closeButton = true;
            toastr.warning('Please select at least one Item')
        }
        else {
            toastr.options.positionClass = 'toast-top-center';
            toastr.options.closeButton = true;
            toastr.options.progressBar = true;
            toastr.success('Downloading...')
            downloadFiles(selectedArr);
        }

    };

    const downloadFiles = async (ids) => {
        try {
            const requestBody = {
                file_ids: ids
            };
            const response = await fetch("https://testcoe1.azurewebsites.net/api/resources/downloadfiles", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody),
                responseType: "blob" // or "arrayBuffer"
            });
            if (!response.ok) {
                throw new Error("Failed to download rows");
            }
            const blob = await response.blob(); // or response.arrayBuffer()
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${props?.companyCode}.zip`; // or other filename
            a.click();
        } catch (error) {
            console.error(error);
        }
    };

    // useEffect(() => {
    //     // add/remove overflow:hidden to body when dialog is opened/closed
    //     document.body.style.overflow = isDeleteDialogueOpen ? 'hidden' : 'auto';
    // }, [isDeleteDialogueOpen]);

    const dialogStyle = {
        display: isDeleteDialogueOpen ? 'block' : 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
        width: '355px',
        maxHeight: 'calc(100% - 100px)',
        overflow: 'auto',
        boxSizing: 'border-box'
    };

    const titleStyle = {
        margin: '0 0 20px',
        fontSize: '20px',
        fontWeight: 'bold'
    };



    return (
        <div>

            <div style={Styles.buttonsContainer}>
                <AddResponse code={props.companyCode} />
                <Button id="download" variant="contained" style={Styles.downloadButton} onClick={downloadSelected} startIcon={<FileDownloadOutlinedIcon fontSize="small" />}>Download</Button>
                <Button variant="contained" id='download' onClick={handleDeleteClick} style={Styles.deleteIcon}><DeleteOutlineIcon /></Button>
                <Button variant="contained" id='refresh-btn' style={Styles.deleteIcon}><RefreshIcon /></Button>
            </div>
            <div style={Styles.tableContainer}>
                <Table id="example" className="display" class='my-custom-class' style={Styles.table}></Table>
            </div>
            <div style={dialogStyle}>
                <h2 style={titleStyle}>Confirm Delete</h2>
                <p>Are you sure you want to delete this item?</p>
                <button style={Styles.buttonStyle} onClick={handleDeleteCancel}>Cancel</button>
                <button style={Styles.buttonStyle} id="delete" onClick={handleClose}>Delete</button>
            </div>
        </div>
    );
};

const Styles = {
    tableContainer: {
        backgroundColor: 'white',
        padding: '15px',
        borderRadius: '10px'
    },
    table: {
        width: "100%",
        backgroundColor: 'white',
        borderCollapse: 'collapse',
    },
    buttonStyle: {
        backgroundColor: '#f44336',
        color: '#fff',
        borderRadius: '4px',
        padding: '10px 20px',
        margin: '0 10px',
        border: 'none',
        cursor: 'pointer',
    },
    downloadButton: {
        backgroundColor: 'white',
        color: '#2924A6',
        fontWeight: 600,
        minWidth: '128px', minWidth: '128px', maxHeight: '36px', minHeight: '30px'
    },
    deleteIcon: {
        cursor: 'pointer',
        alignItems: 'center',
        backgroundColor: 'white',
        color: '#2924A6',
        maxWidth: '30px', maxHeight: '36px', minWidth: '30px', minHeight: '30px'

    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginLeft: '50%',
        marginBottom: '10px',
    }
}
export default ResourceTable;
