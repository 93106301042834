import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    getOrgInfo, getOrgFlexInfo, getOrgMarginInfo, getOrgKeyExecutivesInfo, getOrgKeyRatiosDataInfo,
    getOrgBulletinWindowInfo, getOrgKeyCompetitorsInfo, getOrgsKeyStrategyInfo, getLatestNewsInfo
} from './orgAction'
import './org.css'
import Bulletin from "../../container/BulletinWindow/Bulletin";
import Organizationalbox from "../../container/Flexboxes/organizational";
import KYCMenu from "../../container/menu/Menu";
import Description from "../../container/Description/Description";
import KeyStratergy from "../../container/keyStrategy/KeyStrategy";
import SearchComplete from "../../container/search/Search";
import kycConstants, { topOrgs } from '../../../utils/constants';
import KeyRatiotbl from "../../container/Tables/KeyRatiotbl";
import LineChart from "../../container/charts/lineChart/LineChart";
import KeyCompTable from '../../container/Tables/KeyCompTbl';
import { useLocation } from 'react-router-dom';
import Loader from "../../container/loader/loader";
import { ConnectedTvOutlined } from "@mui/icons-material";
import KeyExecutiveTable from "../../container/Tables/key Executives/keyExecutive";
import { Typography } from "@mui/material";
import ScrollButton from "../../container/ScrollToTop/ScrollUp";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
function Organizationview() {
    //commented to push
    const locData = useLocation();
    const dispatch = useDispatch();
    const orgs = useSelector((state) => state.AllOrgs);
    const [companyCode, setCompanyCode] = useState('')
    let selOrg = sessionStorage.getItem(`org`)
    useEffect(() => {
        if (orgs.orgsSearch && (orgs.selOrg || selOrg)) {
            orgs.orgsSearch.forEach((item) => {
                if (item.company_name === (orgs.selOrg || selOrg))
                    setCompanyCode(item.company_code);
            })
        }
    }, [orgs.selOrg, orgs.orgsSearch])
    useEffect(() => {

        if (companyCode) {
            dispatch(getOrgInfo(companyCode));
            dispatch(getOrgFlexInfo(companyCode));
            dispatch(getOrgMarginInfo(companyCode));
            dispatch(getOrgKeyRatiosDataInfo(companyCode));
            dispatch(getOrgBulletinWindowInfo(companyCode));
            dispatch(getOrgKeyExecutivesInfo(companyCode));
            dispatch(getOrgKeyCompetitorsInfo(companyCode));
            dispatch(getOrgsKeyStrategyInfo(companyCode))
            dispatch(getLatestNewsInfo(companyCode))
        }
    }, [companyCode, dispatch])


    const { instance } = useMsal();
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const msalkey = sessionStorage.getItem('msal.token.keys.9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d')
        if (!msalkey) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        }
    }, [location, companyCode, dispatch])
    return (
        <div>

            <Loader />
            <div>
                <nav>
                    <KYCMenu />
                </nav>
            </div>
            <div>
                <div className="maincontent">
                    <div className="searchbar">
                        <SearchComplete placeHolder={kycConstants.searchOrgPlcHolder}
                            topOrgs={topOrgs} />
                    </div>
                    <div className="org_head">
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'OpenSans' }}>Organizational Overview</Typography>
                        {/* <Typography sx={{ fontSize: '14px', color: '#888888', fontFamily: 'OpenSans' }}>Data Sources: Yahoo Finance,TCS Research Report</Typography> */}
                    </div>
                    <div className="disbox">
                        <Description description={orgs.orgs} />
                    </div>

                    <div className="orgbox">
                        <Organizationalbox data={orgs.orgsFlex} bullet={orgs.orgsBulletinWindow} />
                    </div>
                    <div className="disbox">
                        <KeyStratergy KeyStratergy={orgs.orgsKeyStrategy} />
                    </div>
                    <div className="keyratiotbl">
                        <KeyRatiotbl data={orgs.orgsMarginKeyratiosdata} currencyData={orgs.orgsFlex} />
                    </div>
                    <div className="keycomptbl">
                        <KeyExecutiveTable data={orgs.orgsKeyExecutives} />
                    </div>
                    <div className="keycomptbl">
                        <KeyCompTable data={orgs.orgsKeyCompetitors} currencyData={orgs.orgsFlex} />
                    </div>
                </div>
            </div>
            <div className="sidebar">
                <div className="orgbull" >
                    <Bulletin data={orgs.orgsBulletinWindow} news={orgs.latestNews} />
                </div>
                <ScrollButton />

            </div>


        </div>
    )
}

export default Organizationview;