import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Typography } from '@mui/material';


const NestedTable = (props) => {
    const [show, setShow] = useState(false)
    return (
        <React.Fragment>
            <tr onClick={() => setShow(!show)} className="accTr">
                <td style={{ paddingLeft: `${props.padding}px`, }}>
                    {show ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    {props.heading.metric_name}
                </td>
                {props.heading.values && props.heading.values.map((it) => {
                    return (
                        <td style={{ textAlign: 'right' }}>{it}</td>
                    )
                })}
            </tr>
            {show && props.content && props.content.map((con) => {
                return (
                    (con.child && con.child !== undefined && con.child.length > 0) ?
                        <NestedTable heading={con} content={con.child} padding={props.padding + 15} /> :
                        (
                            <tr >
                                <td style={{ paddingLeft: `${props.padding + 20}px` }}>{con.metric_name}</td>
                                {con.values && con.values.map((it) => {
                                    return (
                                        <td style={{ textAlign: 'right' }}>
                                            {it}
                                        </td>
                                    )
                                })}
                            </tr>
                        ))
            })}

        </React.Fragment>
    )
}


const ParentTable = (props) => {
    if (props?.data?.data?.length <= 0) {
        return (
            <center>
                <Typography>No data available</Typography>
            </center>
        )
    }
    return (
        <table style={{ borderCollapse: 'collapse', borderBottom: '1px solid #ccc' }}>
            <tr >
                <th className='years'>{props.headerVal}</th>
                {props.data?.headers && props.data.headers.map((item) => {
                    return <th className='years' style={{ textAlign: 'right' }}>{item}</th>
                })}
            </tr>
            <tbody className='header' >
                {props.data?.data && props.data.data.map((item) => {
                    return (
                        (item.child && item.child !== undefined && item.child.length > 0) ?
                            (<NestedTable heading={item} content={item.child} padding={5} />) :
                            (
                                <tr >
                                    <td>{item.metric_name}</td>
                                    {item.values && item.values.map((it) => {
                                        return (
                                            <td style={{ textAlign: 'right' }}>{it}</td>
                                        )
                                    })}
                                </tr>
                            )
                    )
                })}
            </tbody>
        </table>
    )
}
export default ParentTable;