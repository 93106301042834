import "./Bulletin.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import NewsContainer from "./LatestNews";
import StockExchangeChart from "./StockExchangeChart";
import { Skeleton } from '@mui/material';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Container,
    CardMedia,
    Link,
} from "@mui/material";
import { QrCodeScannerOutlined } from "@mui/icons-material";



const Bulletin = (props) => {
    // console.log(props?.news, 'latest news')
    const [item, setItemm] = useState(true)
    const orgs = useSelector((state) => state.AllOrgs);
    const [companyCode, setCompanyCode] = useState("");
    let selOrg = sessionStorage.getItem(`org`);
    useEffect(() => {
        if (orgs.orgsSearch && (orgs.selOrg || selOrg)) {
            orgs.orgsSearch.forEach((item) => {
                if (item.company_name === (orgs.selOrg || selOrg))
                    setCompanyCode(item.company_code);
            });
        }
    }, [orgs.selOrg, orgs.orgsSearch]);

    const tryRequire = (path) => {
        try {
            return require(`../../../assets/imgs/company_logos/${companyCode}.png`);
        } catch (err) {
            return null;

        }
    };


    useEffect(() => {
        const bulletinData = props?.data
        if (bulletinData === undefined) {
            setItemm(false)
        }
        else {
            setItemm(true)
        }
    }, [props.data])

    const SkeletonTheme = () => {
        return (
            <Skeleton width={110} />
        )

    }
    return (
        <div style={Styles.mainContainer}>
            <div style={Styles.container}>
                <Typography style={Styles.title}>{item ? (props?.data?.[9]?.value) : (<SkeletonTheme />)}</Typography>
                <div style={Styles.image}>
                    {(companyCode && tryRequire(`../../../assets/imgs/company_logos/${companyCode}.png`)) ?
                        <CardMedia component="img" image={require(`../../../assets/imgs/company_logos/${companyCode}.png`)} alt={`no tagLine`} /> :
                        <CardMedia component="img" image='' alt={`Logo of ${companyCode}`} />}
                </div>



                <div style={Styles.Table}>
                    <TableContainer>
                        <Table>
                            <TableBody
                            // sx={{
                            //     backgroundColor: "#F5F6FB",
                            //     fontWeight: "bold",
                            //     width: "100%",
                            //     fontFamily: 'OpenSans'
                            // }}
                            >
                                <TableRow >
                                    <TableCell align="left" style={Styles.labels} >Headquaters</TableCell>
                                    <TableCell
                                        style={Styles.labelsVal} sx={{ whiteSpace: "nowrap" }} align="right">
                                        {item ? props?.data?.[2]?.value : <SkeletonTheme />}
                                    </TableCell>

                                </TableRow>
                                <TableRow >
                                    <TableCell style={Styles.labels} align="left">Sector</TableCell>
                                    <TableCell style={Styles.labelsVal} sx={{ whiteSpace: "nowrap" }} align="right">{item ? props?.data?.[6]?.value : <SkeletonTheme />}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" style={Styles.labels}>Industry</TableCell>
                                    <TableCell align="right" style={Styles.labelsVal}>{item ? props?.data?.[3]?.value : <SkeletonTheme />}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={Styles.labels} align="left">
                                        Employees
                                    </TableCell>
                                    <TableCell style={Styles.labelsVal} align="right">
                                        {item ? props?.data?.[1]?.value : <SkeletonTheme />}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" style={Styles.labels} >Revenue</TableCell>
                                    <TableCell align="right" style={Styles.labelsVal}>{item ? props?.data?.[5]?.value : <SkeletonTheme />}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={Styles.labels} align="left">
                                        Currency
                                    </TableCell>
                                    <TableCell style={Styles.labelsVal} align="right">
                                        {item ? props?.data?.[0]?.value : <SkeletonTheme />}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" style={Styles.labels}>
                                        CAGR (2 Years)
                                    </TableCell>
                                    <TableCell align="right" style={Styles.labelsVal}>{item ? props?.data?.[4]?.value : <SkeletonTheme />}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={Styles.bulletLabels}>
                        <Typography sx={{ backgroundColor: "#F5F6FB", fontWeight: 700, fontFamily: 'OpenSans', fontSize: '12px', }}>Website</Typography>
                        <Typography style={Styles.link}><Link href={props?.data?.[8]?.value} target="_blank" underline="hover" rel="noopener noreferrer" sx={{ color: '#2924A6', fontWeight: 400, fontSize: '13px' }}>{item ? props?.data?.[8]?.value : <SkeletonTheme />}</Link></Typography>
                        <br />
                        <Typography sx={{ backgroundColor: "#F5F6FB", fontWeight: 700, fontFamily: 'OpenSans', fontSize: '12px', }} >TCS Research Report</Typography>
                        <Typography style={Styles.link}><Link href={props?.data?.[7]?.value} target="_blank" underline="hover" rel="noopener noreferrer" sx={{ color: '#2924A6', fontWeight: 400, fontSize: '13px' }}>{item ? props?.data?.[9]?.value : <SkeletonTheme />}_One Stop Report</Link></Typography>
                    </div>
                </div>
            </div>
            <NewsContainer latestNews={props?.news} />
            {/* <div style={Styles.chartContainer}>
                <StockExchangeChart />
            </div> */}
        </div >
    );
};

const Styles = {
    container: {
        maxWidth: "100%",
        margin: "0 auto",
        backgroundColor: "#F5F6FB",
        borderRadius: "10px",
        fontSize: '10px',
    },
    title: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "black",
        padding: "10px",
        fontFamily: 'OpenSans'
    },
    image: {
        backgroundColor: "#F5F6FB",
        padding: "25px",
    },
    Table: {
        borderRadius: "10px",
        fontWeight: 700,
        fontSize: '10px',
    },
    mainContainer: {
        padding: "10px",
        backgroundColor: "white",
        paddingTop: '0px'
    },
    bulletLabels: {
        padding: '8px',
        wordWrap: "break-word",
        fontSize: '12px'
    },
    chartContainer: {
        padding: '10px',
        backgroundColor: '#F5F6FB',
        borderRadius: "10px",
        marginTop: '10px',
    },
    link: {
        cursor: 'pointer',
        color: 'blue',
        fontFamily: 'OpenSans'
    },
    labelsVal: {
        width: "10%",
        // whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: 500,
        fontFamily: 'OpenSans',
        fontSize: '13px',
        backgroundColor: "#F5F6FB",
        padding: '8px',
        paddingBottom: '5px',

        wordWrap: 'breakWord',
        color: 'black'
    },
    labels: {
        backgroundColor: "#F5F6FB",
        fontWeight: 700,
        fontFamily: 'OpenSans',
        fontSize: '12px',
        padding: '8px',
        paddingBottom: '5px',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }

};
export default Bulletin;
