export const DEF_FIN=`DEF_FIN`;

export const GET_SEARCH ='GET_SEARCH';
export const SET_SEARCH ='SET_SEARCH';
export const GET_COMPETITORS ='GET_COMPETITORS';
export const SET_COMPETITORS ='SET_COMPETITORS';

export const GET_FINANCIAL_PERFOMANCE ='GET_FINANCIAL_PERFOMANCE';
export const SET_FINANCIAL_PERFOMANCE ='SET_FINANCIAL_PERFOMANCE';
export const GET_CUSTOMER_SATISFACTION ='GET_CUSTOMER_SATISFACTION';
export const SET_CUSTOMER_SATISFACTION ='SET_CUSTOMER_SATISFACTION';
export const GET_DIGITAL_PERFORMANCE ='GET_DIGITAL_PERFORMANCE';
export const SET_DIGITAL_PERFORMANCE ='SET_DIGITAL_PERFORMANCE';


export const getbenchmarkFinancialInfo =(params)=>({type:GET_FINANCIAL_PERFOMANCE, companyCode:params});

export const setbenchmarkFinancialInfo=(data)=>({
    type:SET_FINANCIAL_PERFOMANCE,
    data
})

export const getbenchmarkCompetitorsInfo =(params)=>({type:GET_COMPETITORS,companyCode:params});


export const setbenchmarkCompetitorsInfo=(data)=>({
    type:SET_COMPETITORS,
    data
})

export const getbenchmarkCustomerSatisfactionInfo =(params)=>({type:GET_CUSTOMER_SATISFACTION,companyCode:params});


export const setbenchmarkCustomerSatisfactionInfo=(data)=>({
    type:SET_CUSTOMER_SATISFACTION,
    data
})

export const getbenchmarkDigitalPerformanceInfo =(params)=>({type:GET_DIGITAL_PERFORMANCE,companyCode:params});


export const setbenchmarkDigitalPerformanceInfo=(data)=>({
    type:SET_DIGITAL_PERFORMANCE,
    data
})