import { useState, useEffect } from "react";

export const formatLineChartData = (data) => {
    let formatedData = { labels: data?.labels };
    let datasets = [];
    let colors = ["#F78181", "#93CFFA", "#BD94FF"];
    if (data) {
        data.data.forEach((item, i) => {
            let obj = {
                label: item.metric_name,
                backgroundColor: colors[i],
                borderColor: colors[i],
                data: item.values,
            };
            datasets.push(obj);
        });
    }
    formatedData.datasets = datasets;
    return formatedData;
};

export const firstCharUppercase = (str) => {
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    return str2;
};
export const formatBulletinData = (data) => {
    let bulletObj = {
        currency: "",
        employees: "",
        headquarters: "",
        industry: "",
        revenue: "",
        sector: "",
        tcs_research_report: "",
        website: "",
        companyname: ''
    };

    let formatedData = [];
    let keys = Object.keys(data ? data[0] : []);
    let orderedKeys = Object.keys(bulletObj);
    if (data[0]) {
        orderedKeys.forEach((ord) => {
            keys.forEach((item) => {
                // console.log('bullet',ord.includes(item),ord,item)
                if (item.includes(ord)) {
                    let obj = {};
                    obj.title = firstCharUppercase(item);
                    obj.value = data[0][item];
                    formatedData.push(obj);
                }
            });
        });
    }
    return formatedData;
};

export const sortArray = (arr1, arr2) => {
    arr2?.sort((a, b) => {
        const aKey = a.metric_name;
        const bKey = b.metric_name;
        return arr1.indexOf(aKey) - arr1.indexOf(bKey);
    });
};
export const metricName = (name, order) => {
    switch (name) {
        case order[0]:
            return 'Revenue';
        case order[2]:
            return 'Net Margin';
        case order[3]:
            return 'Earnings per share';
        default:
            return name;
    }
};



export const formatMixedChart = (val) => {
    let result = { data: { datasets: [], labels: val.labels } };
    if (val.data) {
        let tempArr = [];
        val.data.forEach((item) => {
            let obj = {};
            if (item.metric_name === "Total Revenue (in Billions)") {
                obj.type = "bar";
            } else {
                obj.type = "line";
            }
            obj.label = item.metric_name;
            obj.data = item.values;
            tempArr.push(obj);
        });
        result.data.datasets = tempArr;
        // console.log("checkresultttttt", result)
    }

    return result;
};

export const formatAgeChart = (val) => {
    let labels = Object.keys(val);
    let data = [];
    let res = {
        labels,
        data,
    };
    labels.forEach((item) => {
        data.push(val[item]);
    });
    res.data = data;
    return res;
};

export const formatdigitalScreenChart = (val) => {
    let formatResponse = {
        last3months_visits: {},
        marketing_channels_dist: {},
        social_network_dist: {},
    };
    let monthsVisit = formatAgeChart(val.last3months_visits);
    let marketingChannel = formatAgeChart(val.marketing_channels_dist);
    let socialNetwork = formatAgeChart(val.social_network_dist);
    formatResponse.last3months_visits = monthsVisit;
    formatResponse.marketing_channels_dist = marketingChannel;
    formatResponse.social_network_dist = socialNetwork;

    return formatResponse;
};

export const formatorgKeyCompetitors = (props) => {
    let formatResponse = {
        headers: {},
        values: {},
    };
    formatResponse.headers = props?.headers;
    formatResponse.values = props?.data?.map((row) => [
        row.organization_name,
        ...row.values,
    ]);
    return formatResponse;
};

export const FormatScrappingData = (props) => {
    let formattedRes = props;
    return formattedRes?.join(' , ')
}


//this function is for splitting the chart values
export const FormatChartData = (data) => {
    let newData = data
    let FormattedArray = []
    newData?.map(item => {
        let val = item.split(" ")
        FormattedArray.push(val[0])
    })
    return FormattedArray;
}

export const getCurrencySymbol = (cur) => {
    const Val = []
    if (cur === 'USD') {
        Val.push('$ ')
    }
    else if (cur === 'CLP') {
        Val.push('CLP$ ')
    }
    else if (cur === 'GBp') {
        Val.push('£ ')
    }
    else if (cur === 'EUR') {
        Val.push('€ ')
    }
    else if (cur === 'CAD') {
        Val.push('Can$ ')
    }
    else if (cur === 'MXN') {
        Val.push('Mex$ ')
    }
    else if (cur === "JPY") {
        Val.push("¥ ");
    }
    return Val
}

// export const SignOutUser = () => {
//     const { instance } = useMsal();
//     useEffect(() => {
//         if (sessionStorage.length === 0) {
//             instance.logoutRedirect({
//                 postLogoutRedirectUri: "/",
//                 // mainWindowRedirectUri: "/"
//             });
//         }
//     }, [])
// }