import { GET_CUST_SAT_PARAMS, SET_CUST_SAT_PARAMS,
GET_GENDER_PARAMS, SET_GENDER_PARAMS,
GET_AGE_PARAMS, SET_AGE_PARAMS,
GET_POS_PARAMS, SET_POS_PARAMS,
GET_CRITICAL_PARAMS, SET_CRITICAL_PARAMS,
GET_WEB_SCRAP_PARAMS, SET_WEB_SCRAP_PARAMS, GET_WORD_CLOUD_PARAMS, SET_WORD_CLOUD_PARAMS
} from './voiceAction'

const initialState = {
}
const VoiceDetails = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUST_SAT_PARAMS: {
            return Object.assign({}, state, {
                voiceCustParams: action.value
            });
        }
        case SET_CUST_SAT_PARAMS: {
            return Object.assign({}, state, {
                voiceCustParams: action.data
            });
        }
       
        case GET_GENDER_PARAMS: {
            return Object.assign({}, state, {
                voiceGenderParams: action.value
            });
        }
        case SET_GENDER_PARAMS: {
            return Object.assign({}, state, {
                voiceGenderParams: action.data
            });
        }
        case GET_AGE_PARAMS: {
            return Object.assign({}, state, {
                voiceAgeParams: action.value
            });
        }
        case SET_AGE_PARAMS: {
            return Object.assign({}, state, {
                voiceAgeParams: action.data
            });
        }
        case GET_POS_PARAMS: {
            return Object.assign({}, state, {
                voicePosParams: action.value
            });
        }
        case SET_POS_PARAMS: {
            return Object.assign({}, state, {
                voicePosParams: action.data
            });
        }
        case GET_CRITICAL_PARAMS: {
            return Object.assign({}, state, {
                voiceCriticalParams: action.value
            });
        }
        case SET_CRITICAL_PARAMS: {
            return Object.assign({}, state, {
                voiceCriticalParams: action.data
            });
        }
        case GET_WEB_SCRAP_PARAMS: {
            return Object.assign({}, state, {
                voiceWebParams: action.value
            });
        }
        case SET_WEB_SCRAP_PARAMS: {
            return Object.assign({}, state, {
                voiceWebParams: action.data
            });
        }

        case GET_WORD_CLOUD_PARAMS: {
            return Object.assign({}, state, {
                voiceWordCloudParams: action.value
            });
        }
        case SET_WORD_CLOUD_PARAMS: {
            return Object.assign({}, state, {
                voiceWordCloudParams: action.data
            });
        }

        default: {
            return Object.assign({}, state);
        }
    }
}
export default VoiceDetails;