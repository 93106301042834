const initialState = {
    DigitalName: ``
}
const DigitalDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SEARCH': {
            return Object.assign({}, state, {
                finSearch: action.value
            });
        }
        case 'SET_SEARCH': {
            return Object.assign({}, state, {
                finSearch: action.data
            });
        }
        case 'DEF_FIN': {
            return Object.assign({}, state, {
                selFin: action.data
            });
        }

        case 'GET_OVERALL_TRAFFIC': {
            return Object.assign({}, state, {
                digitalOverallTraffic: action.value
            });
        }
        case 'SET_OVERALL_TRAFFIC': {
            return Object.assign({}, state, {
                digitalOverallTraffic: action.data
            });
        }

        case 'GET_GRAPH_DATA': {
            return Object.assign({}, state, {
                digitalGraphData: action.value
            });
        }
        case 'SET_GRAPH_DATA': {
            return Object.assign({}, state, {
                digitalGraphData: action.data
            });
        }
        
        default: {
            return Object.assign({}, state);
        }
    }
}
export default DigitalDetails;