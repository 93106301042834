// import React from 'react'
// import '../circularProgressComponent/WebScrapeSource.css'
// import consumer from '../../../assets/imgs/webScraping/consumer.png'
// import customer_service from '../../../assets/imgs/webScraping/customer_service.png'
// import Rectangle from '../../../assets/imgs/webScraping/Rectangle.png'
// import sitejabber from '../../../assets/imgs/webScraping/sitejabber.png'
// import Vector from '../../../assets/imgs/webScraping/Vector.png'
// import { ListItemSecondaryAction } from '@mui/material'

// const WebScrapeSource = (props) => {
//   // console.log('new props',props?.scrapedCompList?.web_scraping_sources)
//   function getLogoForName(name) {
//       switch (name) { 
//         case 'ConsumerAffairs':
//           return consumer;
//         case 'Trustpilot':
//           return Vector;
//         case 'CustomerServiceSCOREBOARD':    
//           return customer_service;  
//         case 'Sitejabber':    
//           return sitejabber; 
//         default:   
//           return 'default-logo.png'; 
//          }}

//   return (
//     <div className='main-content'>
//       <div>
//         <h3 className='web-heading'>Web Scrape Sources</h3>
//         <div className='scrap-data'>
//               {props?.scrapedCompList?.web_scraping_sources.map((item,index)=>(
//                 <li key={index} className='web-container-1'>
//                   <img className='web-frame' src={Rectangle}></img><img className='web-img' src={getLogoForName(item)}></img><h3>{item}</h3>
//                 </li>
//               ))}
//           </div>
          
//       </div>
//     </div>
//   )
// }

// export default WebScrapeSource

import React from 'react'
import '../circularProgressComponent/WebScrapeSource.css'
import consumer from '../../../assets/imgs/webScraping/consumer.png'
import customer_service from '../../../assets/imgs/webScraping/customer_service.png'
import Rectangle from '../../../assets/imgs/webScraping/Rectangle.png'
import sitejabber from '../../../assets/imgs/webScraping/sitejabber.png'
import Vector from '../../../assets/imgs/webScraping/Vector.png'
import { ListItemSecondaryAction } from '@mui/material'

const WebScrapeSource = (props) => {
  function getLogoForName(name) {
      switch (name) { 
        case 'ConsumerAffairs':
          return consumer;
        case 'Trustpilot':
          return Vector;
        case 'CustomerServiceSCOREBOARD':    
          return customer_service;  
        case 'Sitejabber':    
          return sitejabber; 
        default:   
          return 'default-logo.png'; 
         }}

  if (!props?.scrapedCompList?.web_scraping_sources || props?.scrapedCompList?.web_scraping_sources.length === 0){
    return (
      <div className='main-content'>
        <div>
          <h3 className='web-heading'>Web Scrape Sources</h3>
          <div className='scrap-data'>
                   <p>No data available</p>
            </div>
            
        </div>
      </div>
    );
  }
  return (
    <div className='main-content'>
      <div>
        <h3 className='web-heading'>Web Scrape Sources</h3>
        <div className='scrap-data'>
              {props?.scrapedCompList?.web_scraping_sources.map((item,index)=>(
                <li key={index} className='web-container-1'>
                  <img className='web-frame' src={Rectangle}></img><img className='web-img' src={getLogoForName(item)}></img><h3>{item}</h3>
                </li>
              ))}
          </div>
          
      </div>
    </div>
  );
}

export default WebScrapeSource

