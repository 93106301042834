const FinancialGrowbox = (params) => {
  return (
    <div className='flex-main-container'>
      <div className='flex-title'>
        <h3>Customer Satisfaction Parameters</h3>
      </div>
      <div class="flex-container">
        <div class="card"><p>Customer Service Score</p><h3>{params.CustSatParams?.data[0].customer_service_score} / 5</h3></div>
        <div class="card"><div style={{ display: 'flex' }}><p>NPS Score</p><p style={{ fontSize: '10px', padding: '5px' }}>(on scale of -100 to 100)</p></div><h3>{params.CustSatParams?.data[0].nps_score}</h3></div>
        <div class="card"><p>Product Quality Score</p><h3>{params.CustSatParams?.data[0].product_quality_score} / 5</h3></div>
        <div class="card"><p>Price Score</p><h3>{params.CustSatParams?.data[0].price_score} / 5</h3></div>
        <div class="card"><p>Lowest Rated Feature</p><h3>{params.CustSatParams?.data[0].lowest_rated_feature}</h3></div>
        <div class="card"><p>Highest Rated Feature</p><h3>{params.CustSatParams?.data[0].highest_rated_feature}</h3></div>
      </div>
    </div >

  );
};

export default FinancialGrowbox;