import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Margin } from '@mui/icons-material';
import { getCurrencySymbol } from '../../../utils/utils';
import resultsNotFound from '../../../assets/imgs/errorHandlingPngs/resultsNotFound.png'


const KeyCompTable = (props) => {
  // console.log(props?.currencyData?.company_currency,'kkkkkkkkk')

  const tableHeaders = ['Organization Name', 'Revenue', 'Gross Margin ( % )', 'Net Margin ( % )']
  const tableData = props?.data
  if (props?.data?.length <= 0) {
    return (
      <Box sx={{ boxShadow: 'none' }}>
        <div style={Styles.container}>
          <TableContainer component={Paper} >
            <Typography variant="h6" component="h4" style={Styles.title}>
              Competitor Analysis
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={resultsNotFound} alt='No data available' style={{ height: '60%', width: '60%' }} />
            </div>
            {/* <Typography sx={{ textAlign: 'center', paddingBottom: '20px' }}>No data available</Typography> */}
          </TableContainer>
        </div>
      </Box>
    )
  }
  return (

    <div style={Styles.container}>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Typography variant="h6" component="h4" style={Styles.title}>
          Competitor Analysis
        </Typography>
        <Table sx={{ minWidth: 650 }} aria-label="key competitors table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'white' }}>
              {tableHeaders.map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontWeight: 'bold',
                    color: 'black',
                    padding: '10px',
                    textAlign: header === tableHeaders[0] ? 'left' : 'right',
                    fontFamily: 'OpenSans'
                  }}
                  align={header === tableHeaders[0] ? 'left' : 'right'}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody style={Styles.table}>
            {tableData?.map((data, index) => (
              <TableRow key={index} style={Styles.underline}>
                <TableCell style={Styles.table} sx={{ height: '10px', padding: '9px' }} align="left">
                  {data?.organization_name}<br />
                  ({data?.revenue_year})
                </TableCell>
                <TableCell align="right" sx={{ height: '10px' }}>
                  {getCurrencySymbol(data?.company_currency)}{data?.revenue}
                </TableCell>
                <TableCell align="right">
                  {data?.gross_margin}
                </TableCell>
                <TableCell align="right">
                  {data?.net_profit_margin}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ fontSize: '14px', color: '#888888', fontFamily: 'OpenSans' }}>*The financial year of each organization may vary as per the latest published values</Typography>
    </div>
  );
};
const Styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    fontFamily: 'OpenSans',
    boxShadow: 'none'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#2924A6',
    padding: '10px',
    fontFamily: 'OpenSans'
  },
  underline: {
    borderBottom: '1px solid #ccc', // add an underline to the rows
  },
  table: {
    height: '10px',
    fontFamily: 'OpenSans'
  }
}
export default KeyCompTable;

