import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import './voice.css';
import Bulletin from "../../container/BulletinWindow/Bulletin";
import {
    getCustSatParams, getGenderParams, getAgeParams, getPosParams, getCriticalParams,
    getWebScrapParams, getWordCloudParams
} from './voiceAction'
import KYCMenu from "../../container/menu/Menu";
import SearchComplete from "../../container/search/Search";
import kycConstants, { topOrgs } from '../../../utils/constants';
import Voiceflexbox from "../../container/Flexboxes/voiceofcustomer";
import CriticalHighlights from "../../container/criticalHighlights/criticalHighlights";
import PositiveHighlights from "../../container/positiveHighlights/positiveHighlights";
import CustomerDemography from "./customerdemography";
import { getOrgBulletinWindowInfo, getLatestNewsInfo } from '../Oranizational_Overview/orgAction'
import SummaryofFeedback from "../../container/summaryOfFeedback/SummaryofFeedback";
import Loader from "../../container/loader/loader";
import WebScraping from "../../container/webScraping/webscraping";
import { Typography } from "@mui/material";
import { FormatScrappingData } from "../../../utils/utils";
import ScrollButton from "../../container/ScrollToTop/ScrollUp";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

function VoiceofCustomer() {
    const dispatch = useDispatch();
    const orgs = useSelector((state) => state.AllOrgs);
    const voice = useSelector((state) => state.AllVoiceData)
    const [companyCode, setCompanyCode] = useState('')
    let selOrg = sessionStorage.getItem(`org`)
    useEffect(() => {
        if (orgs.orgsSearch && (orgs.selOrg || selOrg)) {
            orgs.orgsSearch.forEach((item) => {
                if (item.company_name === (orgs.selOrg || selOrg))
                    setCompanyCode(item.company_code);
            })
        }
    }, [orgs.selOrg, orgs.orgsSearch])
    useEffect(() => {
        if (companyCode) {
            dispatch(getOrgBulletinWindowInfo(companyCode));
            dispatch(getCustSatParams(companyCode));
            dispatch(getGenderParams(companyCode));
            dispatch(getAgeParams(companyCode));
            dispatch(getPosParams(companyCode));
            dispatch(getCriticalParams(companyCode));
            dispatch(getWordCloudParams(companyCode));
            dispatch(getWebScrapParams(companyCode));
            dispatch(getLatestNewsInfo(companyCode))
        }
    }, [companyCode, dispatch])

    const { instance } = useMsal();
    const location = useLocation();
    useEffect(() => {
        const msalkey = sessionStorage.getItem('msal.token.keys.9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d')
        if (!msalkey) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
        }
    }, [location, companyCode, dispatch])
    return (
        <>
            <Loader />
            <div>
                <Loader />
                <div>
                    <nav>
                        <KYCMenu />
                    </nav>
                </div>
                <div className="maincontent">
                    <div className="searchbar">
                        <SearchComplete placeHolder={kycConstants.searchOrgPlcHolder}
                            topOrgs={topOrgs} />
                    </div>
                    <div>
                        <Typography sx={{ fontWeight: 'bold', marginLeft: '20px', fontFamily: 'OpenSans' }}>Voice Of Customer</Typography>
                        {/* <Typography sx={{ fontSize: '14px', color: '#888888', marginLeft: '20px', fontFamily: 'OpenSans' }}>Data Sources:{FormatScrappingData(voice?.voiceWebParams?.data?.[0]?.web_scraping_sources)}</Typography> */}
                    </div>
                    <div className="voiceflexbox">
                        <Voiceflexbox CustSatParams={voice?.voiceCustParams} />
                    </div>
                    <div className="customergraph">
                        <CustomerDemography
                            genderParams={voice?.voiceGenderParams}
                            ageParams={voice?.voiceAgeParams} />


                    </div>
                    <div className="summaryoffeedback">
                        <SummaryofFeedback
                            wordCloudparams={voice?.voiceWordCloudParams}
                            companyCode={companyCode} />
                    </div>
                    <div className="positiveHighlights">
                        <PositiveHighlights PosParams={voice?.voicePosParams} />
                    </div>
                    <div className="criticalHighlights">
                        <CriticalHighlights CriticalParams={voice?.voiceCriticalParams} />
                    </div>
                    {/* <div>
                        <WebScraping WebScrapingData = {voice?.voiceWebParams}/>
                    </div> */}
                </div>
                <div className="sidebar">
                    <div className="voicebull" >
                        <Bulletin data={orgs.orgsBulletinWindow} news={orgs.latestNews} />
                    </div>
                </div>
                <ScrollButton />
            </div>
        </>
    )
}

export default VoiceofCustomer;