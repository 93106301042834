import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../components/screens/home/Home';
import Menu from '../components/container/menu/Menu';
import NewsFeed from '../components/container/newsFeed/NewsFeed';
import Bulletin from '../components/container/BulletinWindow/Bulletin';
import LineChart from "../components/container/charts/lineChart/LineChart";
import BarChart from "../components/container/charts/barChart/BarChart";
import Description from '../components/container/Description/Description';
import TableData from '../components/container/Tables/KeyRatiotbl';
import KeyCompTbl from '../components/container/Tables/KeyCompTbl';
import PieChart from '../components/container/charts/pieChart/PieChart';
import MixedChart from '../components/container/charts/mixedChart/MixedChart';
import Organizationalbox from '../components/container/Flexboxes/organizational';
import FinancialGrowbox from '../components/container/Flexboxes/financialgrow';
import Digitalbox from '../components/container/Flexboxes/OverallTraffic';
import Voiceflexbox from '../components/container/Flexboxes/voiceofcustomer';
import Organizationview from '../components/screens/Oranizational_Overview/org';
import Accordion from '../components/container/accordion/accordion';
import DynamicTable from '../components/container/dynamicTable/dynamicTable';
import FinancialOverview from '../components/screens/Financial/fin';
import DigitalResearch from '../components/screens/DigitalResearch/DigitalResearch';
import VoiceofCustomer from '../components/screens/VoiceofCustomer/voice';
import Benchmark from '../components/screens/benchmark/Benchmark'
import CircularProgressComponent from '../components/container/circularProgressComponent/circularProgressComponent';
import WebScrapeSource from '../components/container/circularProgressComponent/webScrapeSource';
import Resource from '../components/screens/Resources/Resource';
import SignIn from '../components/container/Login/LoginScreen';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NotFound from '../components/container/NotFound/NotFound';
// while switching the tabs the screen will appear from top(starting)
function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])
    return null;
}

const route = (
    <React.Fragment>
        <ScrollToTop />
        <Routes>
            <Route element={<Home />} path='/' />
            <Route element={<Menu />} path='/menu' />
            <Route element={<NewsFeed />} path='/news' />
            <Route element={<Bulletin />} path='/bulletin' />
            <Route element={<LineChart />} path='/linechart' />
            <Route element={<PieChart />} path='/piechart' />
            <Route element={<MixedChart />} path='/mixedchart' />
            <Route element={<BarChart />} path='/barchart' />
            <Route element={<Description />} path='/description' />
            <Route element={<TableData />} path='/ratiostable' />
            <Route element={<KeyCompTbl />} path='/keycomptbl' />
            <Route element={<Organizationalbox />} path='/organizational' />
            <Route element={<FinancialGrowbox />} path='/financialgrow' />
            <Route element={<Digitalbox />} path='/overalltraffic' />
            <Route element={<Voiceflexbox />} path='/voiceflexbox' />
            <Route element={<Organizationview />} path='/org' />
            <Route element={<FinancialOverview />} path='/fin/*' />
            <Route element={<DigitalResearch />} path='/digitalresearch' />
            <Route element={<Accordion />} path='/accordion' />
            <Route element={<DynamicTable />} path='/dynamictable' />
            <Route element={<VoiceofCustomer />} path='/voiceofcustomer' />
            <Route element={<Benchmark />} path='/benchmark' />
            <Route element={<CircularProgressComponent />} path='/circle' />
            <Route element={<Benchmark />} path='/benchmark' />
            <Route element={<WebScrapeSource />} path='/flex' />
            <Route element={<Resource />} path='/resources' />
            {/* <Route element={<SignIn />} path='/signin' /> */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    </React.Fragment>
)
export default route;

