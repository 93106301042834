// const msalConfig = {
//     auth: {
//         clientId: '9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d',
//         authority: 'https://login.microsoftonline.com/9ac16ca6-063b-4691-aa99-61f232f9b73f',
//         redirectUri: 'http://localhost:3000/home',
//     },
// };

// export default msalConfig;

import { LogLevel } from "@azure/msal-browser";
import * as msal from '@azure/msal-browser';
import { useState, useEffect } from "react";
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

const isLocalhost = window.location.hostname === 'localhost';
const redirectUri = isLocalhost ? 'http://localhost:3000/' : 'https://tcs-core-frontapp.azurewebsites.net/'
// 'https://www.ra-kyc.com/';
// const redirectUri = `${window.location.origin}${baseRedirectUri}`;

const msalConfig = {
    auth: {
        clientId: "9a7a72c8-f9d3-4bb1-af34-9d00d7fd705d",
        authority: "https://login.microsoftonline.com/9ac16ca6-063b-4691-aa99-61f232f9b73f",
        redirectUri: redirectUri
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export default msalConfig;
/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["User.Read"]
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
